import {IOrganisationResponse} from '@joc/api-gateway';

export const getTransformOrg = (organisation: IOrganisationResponse[]) => {
  return organisation.map(({organizationName, id, organizationLogoPath}) => ({
    title: organizationName,
    value: id,
    id,
    imagePath: organizationLogoPath,
  }));
};
