import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {FieldBaseProps} from 'core/types';
//functions
import {getTextDirection} from 'core/functions';
//components
import {Editor} from '@tinymce/tinymce-react';
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './TextEditor.module.scss';

type TextEditorProps = FieldBaseProps;

const TextEditor: FC<TextEditorProps> = (fieldBaseProps) => {
  const {t, i18n} = useTranslation('form');

  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const editorCHangeHandler = (content: string) => {
    setFieldValue(field.name, content);
  };

  const editorBlurHandler = () => {
    setFieldTouched(field.name, true);
  };

  const textDirection = getTextDirection(i18n.language);

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.context}>
        <Editor
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/js/tinymce/tinymce.min.js`}
          id={field.name}
          disabled={disabled}
          onBlur={editorBlurHandler}
          value={field.value}
          onEditorChange={editorCHangeHandler}
          inline
          init={{
            directionality: textDirection,
            height: 100,
            placeholder: t('opportunity.describeOpportunity'),
            menubar: false,
            toolbar: false,
            statusbar: false,
            plugins: 'autolink link',
            default_link_target: '_blank',
            visual: true,
            link_context_toolbar: true,
            visual_anchor_class: 'editor-styles.css',
            newline_behavior: 'linebreak',
            content_style:
              `body #opportunityDescription { height: 100px; padding: 11px 24px 15px 24px; font-size: 18px; line-height: normal; ${
                disabled ? 'color: #b1b7bb; background: #eff1f3;' : 'color: #1c2f4c; background: #fff;'
              } border: 1px solid #C6CCFE; border-radius: 30px; resize: none; overflow-y: auto }` +
              '#opportunityDescription:focus-visible { outline: none }' +
              '#opportunityDescription:focus { border-color: #ffa133; }' +
              '#opportunityDescription::before { font-size: 18px; line-height: normal; fond-weight: normal; color: #5b768b; margin-inline: 24px }' +
              'p { margin: 0 }',
          }}
        />
      </div>
    </FieldWrapper>
  );
};

export default TextEditor;
