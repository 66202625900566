// react
import {Dispatch} from 'redux';
// types
import {ITagRequest, TagRequest, TagsResponse} from '@joc/api-gateway';
import {CREATE_HASHTAG, DELETE_HASHTAG, EDIT_HASHTAG, GET_ALL_HASHTAGS, SET_ERROR} from 'redux/actions-types';
// api
import {API} from '../../core/API';

export const getAllHashtags =
  (orgId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response: TagsResponse[] = await API.getAllTagsByOrganisation(orgId);
      const transformedTagsResponse = response.map((item: TagsResponse) => ({...item, id: +item.id}));

      dispatch({type: GET_ALL_HASHTAGS, payload: transformedTagsResponse});
    } catch (error) {
      const errorMessage = error?.response?.message || error.message;
      dispatch({type: SET_ERROR, payload: {state: true, message: errorMessage}});
      throw error;
    }
  };

export const deleteHashtag =
  (orgId: string, hashtagId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await API.deleteTag(hashtagId, orgId);

      dispatch({
        type: DELETE_HASHTAG,
        payload: {id: hashtagId},
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
      throw error;
    }
  };

export const createHashtag =
  (orgId: string, name: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const tagRequestBody = TagRequest.fromJS({name});
      const response: TagsResponse = await API.createTag(orgId, tagRequestBody);

      const transformedPayload = {
        id: Number(response.id),
        name: response.name,
      };

      dispatch({type: CREATE_HASHTAG, payload: transformedPayload});
    } catch (error) {
      const errorMessage = error?.response?.message || error.message;
      dispatch({type: SET_ERROR, payload: {state: true, message: errorMessage}});
      throw error;
    }
  };

export const editHashtag =
  (hashtagId: number, orgId: string, values: ITagRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const tagRequestBody = TagRequest.fromJS(values);
      const response: TagsResponse = await API.updateGroup2(hashtagId, orgId, tagRequestBody);

      const transformedPayload = {
        id: Number(response.id),
        name: response.name,
      };

      dispatch({type: EDIT_HASHTAG, payload: transformedPayload});
    } catch (error) {
      const errorMessage = error?.response?.message || error.message;
      dispatch({type: SET_ERROR, payload: {state: true, message: errorMessage}});
      throw error;
    }
  };
