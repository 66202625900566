import {FC} from 'react';
//translation
import {useTranslation} from 'react-i18next';
//urls
import {urls} from 'core/appUrls';
//images
import {ReactComponent as HeartSvg} from 'assets/image/heart2.svg';
import {ReactComponent as DiplomSvg} from 'assets/image/diplom.svg';
import {ReactComponent as PinSvg} from 'assets/image/pin.svg';
//components
import HeaderNavItem from './HeaderNavItem';
//styles
import styles from './HeaderNav.module.scss';

const HeaderNav: FC = () => {
  const {t} = useTranslation('header');

  return (
    <nav className={styles.headerNav}>
      <HeaderNavItem linkto={urls.volFeed} linktext={t('nav.link1')}>
        <HeartSvg />
      </HeaderNavItem>
      <HeaderNavItem linkto={urls.volMyFeed} linktext={t('nav.link2')}>
        <PinSvg />
      </HeaderNavItem>
      <HeaderNavItem linkto={urls.volDiploma} linktext={t('nav.link3')}>
        <DiplomSvg />
      </HeaderNavItem>
    </nav>
  );
};

export default HeaderNav;
