import {FC, memo, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {ChannelListTeamProps} from 'stream-chat-react';
import {Channel} from 'stream-chat';
//components
import {SkeletonLoader} from './SkeletonLoader';
import ListHeaderWrapper from './ListHeaderWrapper';
//styles
import './MessagingChannelList.css';

type MessagingChannelListProps = ChannelListTeamProps & {
  onCreateChannel: () => void;
  channel?: Channel;
  isOrg?: boolean;
  children?: ReactNode;
};

const MessagingChannelList: FC<MessagingChannelListProps> = ({children, error = false, isOrg, loading, channel}) => {
  const {t} = useTranslation('errors');

  if (error)
    return (
      <ListHeaderWrapper isOrg={isOrg}>
        <div className="messaging__channel-list__message">{t('errorLoadingConvos')}</div>
      </ListHeaderWrapper>
    );

  if (loading)
    return (
      <ListHeaderWrapper isOrg={isOrg}>
        <div className="messaging__channel-list__message">
          <SkeletonLoader />
        </div>
      </ListHeaderWrapper>
    );

  return (
    <ListHeaderWrapper channel={channel} isOrg={isOrg}>
      {children}
    </ListHeaderWrapper>
  );
};

export default memo(MessagingChannelList);
