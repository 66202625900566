import {
  GradeResponse,
  ICreateNewOrganisationRequest,
  IOrganisationActiveStatusResponse,
  IOrganisationResponse,
  IOrganisationTariffsResponse,
  IVideoResponse,
  ORGANISATION_GENERAL_TYPES,
} from '@joc/api-gateway';
import {Store} from 'redux/root';

export const selectorGetCreateOrgInfo = (store: Store): ICreateNewOrganisationRequest => {
  return store.organizationRedux.createOrganizationInfo;
};

export const selectorGetOrgName = (store: Store): string | undefined => {
  return store.organizationRedux.organizationInfo?.organizationName;
};

export const selectorGetOrgId = (store: Store): number => {
  return Number(store.organizationRedux.organizationInfo?.id);
};

export const selectorGetSchoolId = (store: Store): number | undefined => {
  return Number(store.organizationRedux.organizationInfo?.schoolId);
};

export const selectorGetOrgInfo = (store: Store): IOrganisationResponse | null => {
  return store.organizationRedux.organizationInfo;
};

export const selectorGetOrgSchoolGrade = (store: Store): GradeResponse[] | undefined => {
  return store.organizationRedux.organizationInfo?.school?.grades;
};

export const selectorGetOrgImage = (store: Store): string | undefined => {
  return store.organizationRedux.organizationInfo?.organizationLogoPath;
};

export const selectorGetOrgChatId = (store: Store): string | undefined => {
  return store.organizationRedux.organizationInfo?.chatId;
};

export const selectorGetOrgVideos = (store: Store): Array<IVideoResponse> => {
  return store.organizationRedux.organizationVideos;
};

export const selectorGetOrgGeneralType = (store: Store): ORGANISATION_GENERAL_TYPES | undefined => {
  return store.organizationRedux.organizationInfo?.organisationGeneralType;
};

export const selectorGetOrgActiveStatus = (store: Store): IOrganisationActiveStatusResponse | undefined => {
  return store.organizationRedux.organizationInfo?.organizationActiveStatus;
};

export const selectorGetOrgTariff = (store: Store): IOrganisationTariffsResponse | undefined => {
  return store.organizationRedux.organizationInfo?.organisationTariff;
};

export const selectorGetPossibilityToInvite = (store: Store): boolean | undefined => {
  return !!store.organizationRedux.organizationInfo?.organisationTariff?.invitesAmount;
};
