import {Dispatch} from 'redux';
import {
  IInviteUserRequest,
  INewCreateOrganizationRequest,
  INewCreateUserRequest,
  IUpdateOrganizationRequest,
  UpdateOrganizationRequest,
  UserResponse,
  VOLUNTEER,
} from '@joc/api-gateway';
import {
  GET_ORG_INFO,
  GET_ORG_VIDEOS,
  RESET_CREATE_ORG_INFO,
  RESET_ORG_INFO,
  SET_ERROR,
  SET_ORG_ABOUT_ORG,
  SET_ORG_ABOUT_YOU,
  SET_ORG_CONTACT,
  SET_ORG_INVITED_COLLEAGUES,
  UPLOAD_ORG_VIDEO,
  SET_CURRENT_VIDEO,
  UNSET_CURRENT_VIDEO,
  DELETE_CURRENT_VIDEO,
  ADD_VIDEO_COUNT_WATCH,
  GET_ORG_MEMBERS,
  RESET_ORG_MEMBERS,
  SEARCH_ORG_MEMBERS,
  SET_IS_LOADING_SEARCH_ORG_MEMBERS,
} from 'redux/actions-types';
import {API} from 'core/API';
import {ContactOrg, SearchMemberPayload} from './types';
import {SearchUsersByOrgRequest, USER_ORG_ROLES} from '@joc/api-gateway/lib/api-client';

export const setAboutYouOrg =
  (aboutYouInfo: INewCreateUserRequest) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ORG_ABOUT_YOU,
      payload: aboutYouInfo,
    });
  };

export const setAboutOrg =
  (aboutOrgInfo: INewCreateOrganizationRequest) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ORG_ABOUT_ORG,
      payload: aboutOrgInfo,
    });
  };

export const setContactOrg =
  (contactOrgInfo: ContactOrg) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ORG_CONTACT,
      payload: contactOrgInfo,
    });
  };

export const setInvitedColleaguesOrg =
  (colleaguesArray: Array<IInviteUserRequest>) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ORG_INVITED_COLLEAGUES,
      payload: colleaguesArray,
    });
  };

export const getOrgInfo =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const orgInfoResponse = await API.getOrganisationById(id);
      dispatch({type: GET_ORG_INFO, payload: orgInfoResponse});
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error.message, code: error.response?.code}});
    }
  };

export const resetCreateOrgInfo =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: RESET_CREATE_ORG_INFO,
    });
  };
export const updateOrg = (orgId: number, values: IUpdateOrganizationRequest) => async (dispatch: Dispatch) => {
  try {
    const orgInfoResponse = await API.updateOrganisation(
      orgId,
      orgId.toString(),
      UpdateOrganizationRequest.fromJS(values)
    );
    dispatch({type: GET_ORG_INFO, payload: orgInfoResponse});
  } catch (error) {
    throw error;
  }
};

export const resetOrgInfo = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_ORG_INFO,
  });
};

export const updateOrgInfo =
  (orgId: number, values: any) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      delete values.address;
      const response = await API.updateOrganisation(orgId, orgId.toString(), UpdateOrganizationRequest.fromJS(values));
      dispatch({type: GET_ORG_INFO, payload: response});
    } catch (error) {
      throw new Error(error);
    }
  };

export const uploadOrgVideo =
  (video: File) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const videoResponse = await API.uploadVideo(undefined, {data: video, fileName: video.name});
      dispatch({type: UPLOAD_ORG_VIDEO, payload: videoResponse});
    } catch (error) {
      throw error;
    }
  };

export const setCurrentOrgVideo =
  (id: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await API.setOrganizationVideo(id, undefined);
      dispatch({type: SET_CURRENT_VIDEO, payload: id});
    } catch (error) {
      throw error;
    }
  };

export const unsetCurrentOrgVideo =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await API.removeOrganizationVideo(undefined);
      dispatch({type: UNSET_CURRENT_VIDEO, payload: 0});
    } catch (error) {
      throw error;
    }
  };

export const deleteOrgVideo =
  (id: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await API.deleteVideo(id, undefined);
      dispatch({type: DELETE_CURRENT_VIDEO, payload: id});
    } catch (error) {
      throw error;
    }
  };

export const getOrgVideos =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const videosResponse = await API.getAllVideos(undefined);
      dispatch({type: GET_ORG_VIDEOS, payload: videosResponse});
    } catch (error) {
      throw error;
    }
  };

export const addOrgVideoCountWatch = (id: number) => async (dispatch: Dispatch) => {
  try {
    await API.setCountWatchedOrganizationVideo(id, undefined);
    dispatch({type: ADD_VIDEO_COUNT_WATCH, payload: id});
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getOrgMembers =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({type: SET_IS_LOADING_SEARCH_ORG_MEMBERS, payload: true});
      const membersAdminsResponse = await API.getUsersByOrganization(VOLUNTEER.ADMIN, undefined);
      const membersCoordinatorsResponse = await API.getUsersByOrganization(VOLUNTEER.COORDINATOR, undefined);
      const membersMarketersResponse = await API.getUsersByOrganization(VOLUNTEER.MARKETER, undefined);
      const membersAll: Array<UserResponse> = [];

      const membersAllRaw = [...membersAdminsResponse, ...membersCoordinatorsResponse, ...membersMarketersResponse];
      const membersIds = new Set();

      membersAllRaw.forEach((member) => {
        if (!membersIds.has(member.id)) {
          membersIds.add(member.id);
          membersAll.push(member);
        }
      });

      dispatch({
        type: GET_ORG_MEMBERS,
        payload: {
          all: membersAll,
          admins: membersAdminsResponse,
          coordinators: membersCoordinatorsResponse,
          marketers: membersMarketersResponse,
          isLoading: false,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

export const searchMemberOrg = (id: string, body: SearchUsersByOrgRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: SET_IS_LOADING_SEARCH_ORG_MEMBERS, payload: true});

    const data = await API.organisationSearch(id, body);
    let payload: SearchMemberPayload = {};

    if (body.roleNames && body.roleNames?.length > 1) {
      payload = {all: data.records};
    } else {
      if (!body.roleNames) return;

      switch (body.roleNames[0]) {
        case USER_ORG_ROLES.ADMIN:
          payload = {admins: data.records};
          break;
        case USER_ORG_ROLES.MARKETER:
          payload = {marketers: data.records};
          break;
        default:
          payload = {coordinators: data.records};
          break;
      }
    }

    payload.isLoading = false;

    dispatch({type: SEARCH_ORG_MEMBERS, payload});
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const filterMemberOrg = (id: string, body: SearchUsersByOrgRequest) => async (dispatch: Dispatch) => {
  try {
    const data = await API.organisationSearch(id, body);
    const payload: SearchMemberPayload = {all: data.records};
    dispatch({type: SEARCH_ORG_MEMBERS, payload});
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const resetOrgMembers = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_ORG_MEMBERS,
  });
};
