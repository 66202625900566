import {Field, Form, useFormikContext} from 'formik';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//form configuration
import {EditVolunteerFormValues} from '../formConfiguration';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import {DatePicker} from 'shared/components/Pickers/NewUI/DatePicker';
import GenderSelect from 'shared/components/SelectDropdown/GenderSelect';
import GradeSelect from 'shared/components/SelectDropdown/GradeSelect';
import Input from 'shared/inputs/Input';
//styles
import styles from '../styles.module.scss';

const EditVolunteerMainForm = () => {
  const {t} = useTranslation(['form', 'signUpUser', 'inputs', 'buttons']);

  const {isValid, isSubmitting, dirty} = useFormikContext<EditVolunteerFormValues>();

  const schoolId = useSelector(selectorGetSchoolId);

  return (
    <Form className={styles.form}>
      <Field
        name="firstName"
        label={t('form:user.firstName')}
        placeholder={t('inputs:placeholders.firstName')}
        component={Input}
      />
      <Field
        name="lastName"
        label={t('form:user.lastName')}
        placeholder={t('inputs:placeholders.lastName')}
        component={Input}
      />
      <Field
        name="phoneNumber"
        label={t('form:phoneNumber')}
        placeholder={t('inputs:placeholders.phone')}
        component={InputPhone}
      />
      <Field
        name="birthDate"
        label={t('signUpUser:dob')}
        placeholder={t('inputs:placeholders.dob')}
        component={DatePicker}
        availablePast
      />
      <Field
        name="gender"
        label={t('signUpUser:gender')}
        placeholder={t('inputs:placeholders.gender')}
        component={GenderSelect}
      />
      <Field name="address" label={t('form:address')} component={GoogleApiAutoCompleat} isAddressErrorText />

      {schoolId ? (
        <Field
          name="gradeId"
          label={t('form:schoolGrade')}
          placeholder={t('inputs:placeholders.selectGrade')}
          component={GradeSelect}
        />
      ) : null}

      <Field
        name="studentId"
        label={t('inputs:placeholders.idNumber')}
        placeholder={t('inputs:placeholders.idNumber')}
        component={Input}
      />

      <Field
        name="password"
        label={t('inputs:placeholders.password')}
        placeholder={t('inputs:placeholders.password')}
        component={Input}
      />

      <div className={styles.form__bottom}>
        <ButtonDefault
          classList={['lg', 'primary']}
          title={t('buttons:button.confirm')}
          submitType
          disabled={!isValid || !dirty || isSubmitting}
        />
      </div>
    </Form>
  );
};

export default EditVolunteerMainForm;
