import {FC, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
//translation
import {useTranslation} from 'react-i18next';
//types
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';
//redux
import {Store} from 'redux/root';
import {resetError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
import {selectorGetOrgActiveStatus} from 'redux/organization-service/selector';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import OrgInfoForm from 'components/Organization/EditForms/OrgInfoForm';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
//styles
import styles from './Account.module.scss';

const EditOrg: FC<EditOrgProps> = ({error, resetError, orgStatus}: EditOrgProps) => {
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  const {t} = useTranslation(['editOrg', 'errors', 'messages']);

  const isOrgSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;

  return (
    <div className={styles.container}>
      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonClickHandler={resetError}
          buttonTitle={t('errors:checkAndTryAgain')}
          styleTable
        />
      ) : isUpdateSuccess ? (
        <CongratsPopup
          subtitle={t('messages:orgHasBeenUpdatedSuccessfully')}
          doneButtonClickHandler={() => setIsUpdateSuccess(false)}
        />
      ) : (
        <WhiteContainer title={t('editOrg:orgInfo')}>
          <OrgInfoForm setIsUpdateSuccess={setIsUpdateSuccess} isOrgSuspended={isOrgSuspended} />
        </WhiteContainer>
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  error: selectorGetError(store),
  orgStatus: selectorGetOrgActiveStatus(store),
});

const mapDispatchToProps = {
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditOrgProps = ConnectedProps<typeof connector>;

export default connector(EditOrg);
