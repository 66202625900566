import {FC, memo, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useChannelContext, useChatContext} from 'stream-chat-react';
import type {ChannelMemberResponse} from 'stream-chat';
//redux
import {
  AttachmentType,
  ChannelType,
  CommandType,
  EventType,
  MessageType,
  ReactionType,
  UserType,
} from 'redux/chat-service/actions';
//functions
import {getChannelMembers, getChannelName} from 'core/functions';
//images
import {ChannelInfoIcon, ChannelSaveIcon, getCleanImage, HamburgerIcon} from 'shared/components/RandomImage';
//components
import CustomAvatar from 'shared/components/ChatOk/ChatComponents/CustomAvatar';
//styles
import './MessagingChannelHeader.css';

export const AvatarGroup = ({members}: {members: ChannelMemberResponse[]}) => {
  switch (members.length) {
    case 1:
      return (
        <div className="messaging__channel-header__avatars">
          <CustomAvatar image={getCleanImage(members[0])} size={40} />;
        </div>
      );

    case 2:
      return (
        <div className="messaging__channel-header__avatars two">
          <span>
            <CustomAvatar image={getCleanImage(members[0])} shape="square" size={40} />
          </span>
          <span>
            <CustomAvatar image={getCleanImage(members[1])} shape="square" size={40} />
          </span>
        </div>
      );

    case 3:
      return (
        <div className="messaging__channel-header__avatars three">
          <span>
            <CustomAvatar image={getCleanImage(members[0])} shape="square" size={40} />
          </span>
          <span>
            <CustomAvatar image={getCleanImage(members[1])} shape="square" size={20} />
            <CustomAvatar image={getCleanImage(members[2])} shape="square" size={20} />
          </span>
        </div>
      );

    default:
      return (
        <div className="messaging__channel-header__avatars four">
          <span>
            <CustomAvatar image={getCleanImage(members[members.length - 1])} shape="square" size={20} />
            <CustomAvatar image={getCleanImage(members[members.length - 2])} shape="square" size={20} />
          </span>
          <span>
            <CustomAvatar image={getCleanImage(members[members.length - 3])} shape="square" size={20} />
            <CustomAvatar image={getCleanImage(members[members.length - 4])} shape="square" size={20} />
          </span>
        </div>
      );
  }
};

type MessagingChannelHeaderProps = {
  theme: string;
};

const MessagingChannelHeader: FC<MessagingChannelHeaderProps> = ({theme}) => {
  const {client} = useChatContext<
    AttachmentType,
    ChannelType,
    CommandType,
    EventType,
    MessageType,
    ReactionType,
    UserType
  >();

  const {channel} = useChannelContext<
    AttachmentType,
    ChannelType,
    CommandType,
    EventType,
    MessageType,
    ReactionType,
    UserType
  >();

  const members: Array<ChannelMemberResponse> = getChannelMembers(channel.state.members, client?.user?.id);
  const [selection, setSelection] = useState<[number | null, number | null] | null>(null);
  const [channelName, setChannelName] = useState(getChannelName(members, channel));
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState('');

  const {t} = useTranslation(['messages', 'chat', 'inputs']);

  const inputRef = useRef<HTMLInputElement>(null);

  const updateChannel = async () => {
    if (channelName && channelName !== channel.data?.name) {
      await channel.update({name: channelName}, {text: `${t('messages:channelNameChangedTo')} ${channelName}`});
    }

    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef?.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (!channelName)
      setTitle(members.map((member) => member.user?.name || member.user?.id || t('chat:unnamedUser')).join(', '));
  }, [channelName, members]);

  useLayoutEffect(() => {
    if (selection && inputRef.current) {
      [inputRef.current.selectionStart, inputRef.current.selectionEnd] = selection;
    }
  }, [selection]);

  const EditHeader = () => (
    <form
      style={{flex: 1}}
      onSubmit={(event) => {
        event.preventDefault();
        inputRef?.current?.blur();
      }}
    >
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        className="channel-header__edit-input"
        onBlur={updateChannel}
        onChange={(event) => {
          setChannelName(event.target.value);
          setSelection([event.target.selectionStart, event.target.selectionEnd]);
        }}
        placeholder={t('inputs:placeholders.typeNewChatName')}
        ref={inputRef}
        value={channelName}
      />
    </form>
  );

  return (
    <div className="messaging__channel-header">
      <div id="mobile-nav-icon" className={`${theme}`}>
        <HamburgerIcon />
      </div>

      <AvatarGroup members={members as any} />

      {!isEditing ? <div className="channel-header__name">{channelName || title}</div> : <EditHeader />}

      <div className="messaging__channel-header__right">
        {channelName !== t('chat:socialDemo') &&
          (!isEditing ? <ChannelInfoIcon {...{isEditing, setIsEditing}} /> : <ChannelSaveIcon />)}
      </div>
    </div>
  );
};

export default memo(MessagingChannelHeader);
