import {FC, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Link} from 'react-router-dom';
import cx from 'classnames';
//translation
import {useTranslation} from 'react-i18next';
//urls
import {urls} from 'core/appUrls';
//images
import GreetingSvg from 'assets/image/onboarding/Greeting.svg';
//redux
import {resetError} from 'redux/error-service/action';
import {userResetData} from 'redux/user-service/actions';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './ChooseUserType.module.scss';

const ChooseUserType: FC<ChooseUserTypeProps> = ({resetError, userResetData}: ChooseUserTypeProps) => {
  const {t, i18n} = useTranslation(['chooseAccType', 'common']);

  useEffect(() => {
    localStorage.clear();
    resetError();
    userResetData();

    return () => {
      resetError();
      localStorage.setItem('i18nextLng', i18n.language);
    };
  }, []);

  return (
    <div className={styles.content}>
      <div className={styles.content__greeting}>
        <img src={GreetingSvg} className={styles.greeting__image} alt="" />
        <span className={styles.greeting__title}>{t('chooseAccType:whoAmI')}</span>
      </div>
      <div className={styles.container}>
        <Link to={urls.onboardingLoginVolunteer}>
          <ButtonDefault classList={['secondary', 'lg']} title={t('chooseAccType:volunteer')} />
        </Link>
        <Link to={urls.onboardingLoginOrganization}>
          <ButtonDefault classList={['primary', 'lg']} title={t('chooseAccType:organization')} />
        </Link>
      </div>
      <div className={styles.linkBlock}>
        {process.env.REACT_APP_JOCP_ENV !== 'STAGE' && process.env.REACT_APP_JOCP_ENV}
        <Link to={urls.about} className={cx('link', styles.createAccount__link)}>
          {t('common:loginFooter.donate')}
        </Link>
        <Link to={urls.getInTouch} className={cx('link', styles.createAccount__link)}>
          {t('common:loginFooter.contact')}
        </Link>
        <Link to={urls.terms} className={cx('link', styles.createAccount__link)}>
          {t('common:loginFooter.terms')}
        </Link>
        <Link to={urls.policy} className={cx('link', styles.createAccount__link)}>
          {t('common:loginFooter.policy')}
        </Link>
        <Link to={urls.tariffPlans} className={cx('link', styles.createAccount__link)}>
          {t('common:loginFooter.pricing')}
        </Link>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  resetError,
  userResetData,
};

const connector = connect(null, mapDispatchToProps);

type ChooseUserTypeProps = ConnectedProps<typeof connector>;

export default connector(ChooseUserType);
