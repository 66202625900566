// react
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from 'react';
// redux
import {Store} from 'redux/root';
import {getAllHashtags} from 'redux/hashtag-service/actions';
import {selectorGetUserId} from 'redux/user-service/selector';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetHashtags} from 'redux/hashtag-service/selectors';
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {createManualOpportunityAdmin} from 'redux/opportunities-service/action';
//formik
import {Formik} from 'formik';
// interfaces
import {CreateManualOppModalData} from '../Volunteers/Table/interfaces';
// components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
import {CreateManualOppForm} from '../CreateManualOppoForm/CreateManualOppoForm';
import PopupMain from 'components/Organization/Opportunities/OpportunityPopups/PopupMain';
// types
import {ICreateOpportunityRequest} from '@joc/api-gateway';
// config
import {CREATE_ADMIN_MANUAL_OPPORTUNITY_INIT_VALUES} from './config';
// validation
import {opportunityAdminManualValidationSchema} from 'core/validation';
// hooks
import {useLoading} from 'hooks/useLoading';
//styles
import styles from './index.module.scss';

interface ICreateManualOppPopupProps {
  data: CreateManualOppModalData | null;
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
}

export const CreateManualOppPopup: FC<ICreateManualOppPopupProps> = ({setIsOpenPopup, data}) => {
  const dispatch = useDispatch();
  const {isLoading, startLoading} = useLoading();
  const {t} = useTranslation(['errors', 'buttons', 'messages', 'form', 'popup']);

  const orgHashtags = useSelector(selectorGetHashtags);
  const organisationId = useSelector(selectorGetOrgId).toString();
  const error = useSelector((store: Store) => store.errorRedux.error);
  const userId = useSelector((store: Store) => selectorGetUserId(store));

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState(false);

  const initValues = useMemo(
    () => ({
      ...CREATE_ADMIN_MANUAL_OPPORTUNITY_INIT_VALUES,
      organisationId,
      user: userId ? +userId : 0,
      volunteerId: data?.volunteerId ? +data.volunteerId : 0,
    }),
    [organisationId, userId, data]
  );

  useEffect(() => {
    if (organisationId) {
      getAllOrgHashtags(organisationId);
    }
  }, [organisationId]);

  const getAllOrgHashtags = async (orgId: string) => {
    await startLoading(getAllHashtags(orgId));
  };

  const handlerResetError = () => dispatch(resetError());

  const handleSubmit = async (values: ICreateOpportunityRequest): Promise<void> => {
    await startLoading(createManualOpportunityAdmin(values));
    setIsSubmitted(true);
  };

  const handleSuccessSubmitForm = () => {
    data?.refreshVolunteers?.();
    setIsOpenPopup(false);
  };

  return (
    <PopupMain isDisablePadding setIsShowPopup={setIsOpenPopup}>
      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonClickHandler={handlerResetError}
          buttonTitle={t('errors:checkAndTryAgain')}
        />
      ) : isSubmitted ? (
        <CongratsPopup
          isFullHeight
          buttonTitle={t('buttons:button.done')}
          subtitle={t('messages:createdManualChesedOppo')}
          doneButtonClickHandler={handleSuccessSubmitForm}
        />
      ) : (
        <Appeal styleContact>
          <div className={styles.title}>{t('form:createManualOpp.title')}</div>
          {data?.volunteerName && (
            <div className={styles.subtitle}>
              <span className={styles.subtitle__pre}>For:</span>
              {data?.volunteerName}
            </div>
          )}
          <div className={styles.form__container}>
            <Formik
              validateOnChange
              enableReinitialize
              initialValues={initValues}
              validationSchema={opportunityAdminManualValidationSchema}
              onSubmit={(values) => {
                // @ts-ignore
                handleSubmit(values as ICreateOpportunityRequest);
              }}
            >
              <CreateManualOppForm
                options={orgHashtags}
                isLoading={isLoading}
                isVisibleHashtagField={isVisibleHashtagField}
                setIsVisibleHashtagField={setIsVisibleHashtagField}
              />
            </Formik>
          </div>
        </Appeal>
      )}
    </PopupMain>
  );
};
