import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar, useChatContext} from 'stream-chat-react';
import type {UserResponse} from 'stream-chat';
//redux
import {
  AttachmentType,
  ChannelType,
  CommandType,
  EventType,
  MessageType,
  ReactionType,
  UserType,
} from 'redux/chat-service/actions';
//hooks
import {useDebounceValue} from 'core/customHooks';
//images
import {XButton, XButtonBackground} from '../../../RandomImage';
//styles
import './CreateChannel.css';

const UserResult = ({user}: {user: UserResponse}) => (
  <li className="messaging-create-channel__user-result">
    <Avatar image={user.image as string} size={57} />
    {user.online && <div className="messaging-create-channel__user-result-online" />}
    <div className="messaging-create-channel__user-result__details">
      <span>{user.name}</span>
    </div>
  </li>
);

type Props = {
  onClose: () => void;
};

const CreateChannel: React.FC<Props> = (props) => {
  const {onClose} = props;

  const {client, setActiveChannel} = useChatContext<
    AttachmentType,
    ChannelType,
    CommandType,
    EventType,
    MessageType,
    ReactionType,
    UserType
  >();

  const [focusedUser, setFocusedUser] = useState<number>();
  const [inputText, setInputText] = useState('');
  const [resultsOpen, setResultsOpen] = useState(false);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<UserResponse[]>([]);
  const [users, setUsers] = useState<UserResponse[]>([]);

  const {t} = useTranslation(['chat', 'inputs', 'messages']);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const clickListener = () => {
      if (resultsOpen) clearState();
    };

    document.addEventListener('click', clickListener);

    return () => document.removeEventListener('click', clickListener);
  }, []);

  const clearState = () => {
    setInputText('');
    setResultsOpen(false);
    setSearchEmpty(false);
  };

  const findUsers = async () => {
    if (searching) return;
    setSearching(true);

    try {
      const response = await client.queryUsers(
        {
          id: {$ne: client.userID as string},
          $and: [
            {name: {$autocomplete: debouncedText || ''}},
            {name: {$nin: ['Daniel Smith', 'Kevin Rosen', 'Jen Alexander']}},
          ],
        },
        {id: 1},
        {limit: 6}
      );

      if (!response.users.length) {
        setSearchEmpty(true);
      } else {
        setSearchEmpty(false);
        setUsers(response.users);
      }

      setResultsOpen(true);
    } catch (error) {
      console.error({error});
    }

    setSearching(false);
  };

  const debouncedText = useDebounceValue(inputText);

  useEffect(() => {
    findUsers();
  }, [debouncedText]);

  const createChannel = async () => {
    const selectedUsersIds = selectedUsers.map((u) => u.id);

    if (!selectedUsersIds.length || !client.userID) return;

    const conversation = await client.channel('messaging', {
      members: [...selectedUsersIds, client.userID],
    });

    await conversation.watch();

    setActiveChannel?.(conversation);
    setSelectedUsers([]);
    setUsers([]);
    onClose();
  };

  const addUser = (addedUser: UserResponse) => {
    const isAlreadyAdded = selectedUsers.find((user) => user.id === addedUser.id);
    if (isAlreadyAdded) return;

    setSelectedUsers([...selectedUsers, addedUser]);
    setResultsOpen(false);
    setInputText('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeUser = (user: UserResponse) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id);
    setSelectedUsers(newUsers);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // check for up(ArrowUp) or down(ArrowDown) key
      if (event.key === 'ArrowUp') {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === 0 ? users.length - 1 : prevFocused - 1;
        });
      }
      if (event.key === 'ArrowDown') {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === users.length - 1 ? 0 : prevFocused + 1;
        });
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        if (focusedUser !== undefined) {
          addUser(users[focusedUser]);
          return setFocusedUser(undefined);
        }
      }
    },
    [users, focusedUser]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="messaging-create-channel">
      <header>
        <div className="messaging-create-channel__left">
          <div className="messaging-create-channel__left-text">To: </div>
          <div className="users-input-container">
            {!!selectedUsers?.length && (
              <div className="messaging-create-channel__users">
                {selectedUsers.map((user) => (
                  <div className="messaging-create-channel__user" onClick={() => removeUser(user)} key={user.id}>
                    <div className="messaging-create-channel__user-text">{user.name}</div>
                    <XButton />
                  </div>
                ))}
              </div>
            )}
            <form>
              <input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                ref={inputRef}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder={!selectedUsers.length ? t('inputs:placeholders.startTypingForSuggestions') : ''}
                type="text"
                className="messaging-create-channel__input"
              />
            </form>
          </div>
          <div className="close-mobile-create">
            <XButtonBackground />
          </div>
        </div>
        <button className="create-channel-button" onClick={createChannel}>
          {t('chat:startChat')}
        </button>
      </header>
      {inputText && (
        <main>
          <ul className="messaging-create-channel__user-results">
            {!!users?.length && !searchEmpty && (
              <div>
                {users.map((user, i) => (
                  <div
                    className={`messaging-create-channel__user-result ${focusedUser === i && t('chat:focused')}`}
                    onClick={() => addUser(user)}
                    key={user.id}
                  >
                    <UserResult user={user} />
                  </div>
                ))}
              </div>
            )}
            {searchEmpty && (
              <div
                onClick={() => {
                  inputRef.current?.focus();
                  clearState();
                }}
                className="messaging-create-channel__user-result empty"
              >
                {t('messages:noPeopleFound')}
              </div>
            )}
          </ul>
        </main>
      )}
    </div>
  );
};

export default memo(CreateChannel);
