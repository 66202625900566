import {FC} from 'react';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import PopupContainer from 'shared/components/PopupContainer';
import TermsInfo from 'pages/Terms/TermsInfo';

type TermsPopupProps = {
  setIsShowPopup: () => void;
};

const TermsPopup: FC<TermsPopupProps> = ({setIsShowPopup}: TermsPopupProps) => {
  return (
    <PopupContainer setIsShowPopup={setIsShowPopup} isFixedStyle isDisablePadding isDisableContentMarginTop>
      <WhiteContainer title="Terms">
        <TermsInfo />
      </WhiteContainer>
    </PopupContainer>
  );
};

export default TermsPopup;
