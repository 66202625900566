import {FC} from 'react';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
//styles
import styles from './TermsInfo.module.scss';

type ListItem = {title: string; paragraf: string; sublist: Array<string>};

const getListNode = (item: ListItem, index: number) => (
  <li key={index}>
    <span>{item.title}</span>
    <span>{item.paragraf}</span>
    {item.sublist && (
      <ul>
        {item.sublist.map((listItem: any, index: number) =>
          typeof listItem === 'string' ? (
            <li key={index}>{listItem}</li>
          ) : (
            <li>
              <span>{listItem.title}</span>
              <span>{listItem.text}</span>
              {listItem.sublist && (
                <ul>
                  {listItem.sublist.map((sublistItem: string, index: number) => (
                    <li key={index}>{sublistItem}</li>
                  ))}
                </ul>
              )}
            </li>
          )
        )}
      </ul>
    )}
  </li>
);

const getArr = (key: string): Array<any> => i18next.t(key, {returnObjects: true}) || [];

const TermsInfo: FC = () => {
  const {t} = useTranslation('terms');

  const testList = getArr('terms:context.2.content.text');

  return (
    <div className={styles.content}>
      {/* <div className={styles.article__text}>{i18next.t('terms:context.0.content.paragraf') as string}</div>
      <h2 className={styles.title}>{t('terms:context.1.title')}</h2>
      <div className={styles.article__text}>{i18next.t('terms:context.1.content.paragraf') as string}</div>
      <h2 className={styles.title}>{t('terms:context.2.title')}</h2>
      <ol>{!!testList.length && testList.map(getListNode)}</ol>
      <h2 className={styles.title}>{t('terms:context.3.title')}</h2>
      <h3 className={styles.title}>{t('terms:context.3.content.0.title')}</h3>
      <ol>{(i18next.t('terms:context.3.content.0.text', {returnObjects: true}) as Array<any>).map(getListNode)}</ol>
      <h3 className={styles.title}>{t('terms:context.3.content.1.title')}</h3>
      <ol>{(i18next.t('terms:context.3.content.1.text', {returnObjects: true}) as Array<any>).map(getListNode)}</ol>
      <h3 className={styles.title}>{t('terms:context.3.content.2.title')}</h3>
      <ol>{(i18next.t('terms:context.3.content.2.text', {returnObjects: true}) as Array<any>).map(getListNode)}</ol>
      <h3 className={styles.title}>{t('terms:context.3.content.3.title')}</h3>
      <ol>{(i18next.t('terms:context.3.content.3.text', {returnObjects: true}) as Array<any>).map(getListNode)}</ol>
      <h3 className={styles.title}>{t('terms:context.3.content.4.title')}</h3>
      <ol>{(i18next.t('terms:context.3.content.4.text', {returnObjects: true}) as Array<any>).map(getListNode)}</ol>
      <h3 className={styles.title}>{t('terms:context.3.content.5.title')}</h3>
      <ol>{(i18next.t('terms:context.3.content.5.text', {returnObjects: true}) as Array<any>).map(getListNode)}</ol>
      <h3 className={styles.title}>{t('terms:context.3.content.6.title')}</h3>
      <ol>{(i18next.t('terms:context.3.content.6.text', {returnObjects: true}) as Array<any>).map(getListNode)}</ol>
      <h3 className={styles.title}>{t('terms:context.3.content.7.title')}</h3>
      <ol>{(i18next.t('terms:context.3.content.7.text', {returnObjects: true}) as Array<any>).map(getListNode)}</ol>
      <h3 className={styles.title}>{t('terms:context.3.content.8.title')}</h3>
      <ol>{(i18next.t('terms:context.3.content.8.text', {returnObjects: true}) as Array<any>).map(getListNode)}</ol>
      <div>{t('terms:context.4.title')}</div> */}
    </div>
  );
};

export default TermsInfo;
