import {ChangeEventHandler, FC, useCallback, useState, memo} from 'react';
import {useTranslation} from 'react-i18next';
//chat
import {Attachment, logChatPromiseExecution, UserResponse} from 'stream-chat';
import {
  ChatAutoComplete,
  MessageInputProps,
  StreamMessage,
  UploadsPreview,
  useChannelContext,
  useMessageInput,
} from 'stream-chat-react';
//redux
import {
  AttachmentType,
  ChannelType,
  CommandType,
  EventType,
  MessageType,
  ReactionType,
  UserType,
} from 'redux/chat-service/actions';
//images
import {EmojiIcon, LightningBoltSmall, SendIcon} from '../../../RandomImage';
//components
import {CustomFileDropzone} from '../FileDropzone/CustomFileDropzone';
import CustomEmojiPicker from '../EmojiPicker/CustomEmojiPicker';
//styles
import './MessagingInput.css';

const GiphyIcon = () => (
  <div className="giphy-icon__wrapper">
    <LightningBoltSmall />
    <p className="giphy-icon__text">GIPHY</p>
  </div>
);

const MessagingInput: FC<MessageInputProps> = (props) => {
  const {acceptedFiles, maxNumberOfFiles, multipleUploads, sendMessage} = useChannelContext<
    AttachmentType,
    ChannelType,
    CommandType,
    EventType,
    MessageType,
    ReactionType,
    UserType
  >();

  const [giphyState, setGiphyState] = useState(false);

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const {t} = useTranslation('inputs');

  const overrideSubmitHandler = (message: {
    attachments: Attachment[];
    mentioned_users: UserResponse[];
    text: string;
    parent?: StreamMessage;
  }) => {
    let updatedMessage;

    if (message.attachments?.length && message.text?.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '');
      updatedMessage = {...message, text: updatedText};
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = {...message, text: updatedText};
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      };

      const sendMessagePromise = sendMessage(messageToSend as any);
      logChatPromiseExecution(sendMessagePromise, 'send message');
    }

    setGiphyState(false);
  };

  const messageInput = useMessageInput({...(props as any), overrideSubmitHandler});

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      const {value} = event.target;

      const deletePressed = !!(
        event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType === 'deleteContentBackward'
      );

      if (messageInput.text.length === 1 && deletePressed) {
        setGiphyState(false);
      }

      if (!giphyState && messageInput.text.startsWith('/giphy') && !messageInput.numberOfUploads) {
        event.target.value = value.replace('/giphy', '');
        setGiphyState(true);
      }

      messageInput.handleChange(event);
    },
    [giphyState, messageInput]
  );

  const changeEmojiPickerOpenStatus = () => setIsEmojiPickerOpen((prevStatus) => !prevStatus);

  return (
    <div className="str-chat__messaging-input">
      <div
        className="messaging-input__button emoji-button"
        role="button"
        aria-roledescription="button"
        onClick={changeEmojiPickerOpenStatus}
        ref={messageInput.emojiPickerRef}
      >
        <EmojiIcon />
      </div>
      <CustomFileDropzone
        accept={acceptedFiles}
        handleFiles={messageInput.uploadNewFiles}
        multiple={multipleUploads}
        disabled={(maxNumberOfFiles !== undefined && messageInput.numberOfUploads >= maxNumberOfFiles) || giphyState}
      >
        <div className="messaging-input__input-wrapper">
          {giphyState && !messageInput.numberOfUploads && <GiphyIcon />}
          <UploadsPreview {...messageInput} />
          <ChatAutoComplete
            commands={messageInput.getCommands()}
            innerRef={messageInput.textareaRef}
            handleSubmit={messageInput.handleSubmit}
            onSelectItem={messageInput.onSelectItem}
            onChange={onChange}
            value={messageInput.text}
            rows={1}
            maxRows={props.maxRows}
            placeholder={t('placeholders.sendMessageOrDropFile')}
            onPaste={messageInput.onPaste}
            triggers={props.autocompleteTriggers}
            grow={props.grow}
            disabled={props.disabled}
            additionalTextareaProps={props.additionalTextareaProps}
          />
        </div>
      </CustomFileDropzone>
      <div
        className="messaging-input__button"
        role="button"
        aria-roledescription="button"
        onClick={messageInput.handleSubmit}
      >
        <SendIcon />
      </div>
      <CustomEmojiPicker
        {...messageInput}
        isOpen={isEmojiPickerOpen}
        changeIsOpenStatus={changeEmojiPickerOpenStatus}
      />
    </div>
  );
};

export default memo(MessagingInput);
