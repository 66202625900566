import {
  IDiplomasResponse,
  IGetLocationAddressByIdResponse,
  IOrganisationResponse,
  IRolesResponse,
  IUserResponse,
  UserOrganizationsResponse,
  VOLUNTEER,
  VOLUNTEER_ACTIVE_STATUS,
} from '@joc/api-gateway';

import {Store} from 'redux/root';

export const selectorGetUserData = (store: Store): IUserResponse | null => {
  return store.userRedux.userData;
};

export const selectorGetUserRoles = (store: Store): Array<IRolesResponse> | undefined => {
  return store.userRedux.userData?.roles;
};

export const selectorGetUserRolesByOrg = (store: Store): Array<IRolesResponse> | undefined => {
  const orgId = Number(store.organizationRedux.organizationInfo?.id);

  return store.userRedux.userData?.roles?.filter((role) => Number(role?.organizationId) === orgId);
};

export const selectorIsUserMarketer = (store: Store): boolean => {
  const orgId = Number(store.organizationRedux.organizationInfo?.id);
  const roles = store.userRedux.userData?.roles;

  return !roles
    ?.filter((role) => Number(role?.organizationId) === orgId)
    .filter((role) => role.roleName !== VOLUNTEER.MARKETER && role.roleName !== VOLUNTEER.VOLUNTEER).length;
};
export const selectorGetUserFullName = (store: Store): string => {
  return store.userRedux.userData?.firstName && store.userRedux.userData?.lastName
    ? `${store.userRedux.userData?.firstName} ${store.userRedux.userData?.lastName}`
    : '';
};

export const selectorGetUserPosition = (store: Store): Array<string> | undefined => {
  return store.userRedux.userData?.roles?.map((i) => i.roleName.toLowerCase());
};
export const selectorGetUserImage = (store: Store): string | undefined => {
  return store.userRedux.userData?.imagePath;
};

export const selectorGetOrgLogo = (store: Store): string | undefined => {
  return store.organizationRedux.organizationInfo?.organizationLogoPath;
};

export const selectorGetUserOrganizations = (store: Store): Array<IOrganisationResponse> | undefined => {
  return store.userRedux.userData?.organizations;
};

export const selectorGetUserOrganizationsWithRoles = (store: Store): Array<UserOrganizationsResponse> | undefined => {
  return store.userRedux.userData?.userOrganizations;
};

export const selectorGetUserVolunteerId = (store: Store): string | undefined => {
  return store.userRedux.userData?.volunteerId;
};

export const selectorGetUserId = (store: Store): number | undefined => {
  return store.userRedux.userData?.id ? +store.userRedux.userData?.id : undefined;
};

export const selectorGetHiddenNavbarModeState = (store: Store): boolean => {
  return store.userRedux.navbarHiddenMode;
};

export const selectorGetChatId = (store: Store): string | undefined => {
  return store.userRedux.userData?.chatId;
};

export const selectorGetUserVolunteerDiploma = (store: Store): IDiplomasResponse | undefined => {
  return store.userRedux.userData?.diploma;
};

export const selectorGetUserVolunteerPoints = (store: Store): number | undefined => {
  return store.userRedux.userData?.points;
};

export const selectorGetUserVolunteerStatus = (store: Store): VOLUNTEER_ACTIVE_STATUS | undefined => {
  return store.userRedux.userData?.volunteerActiveStatus;
};

export const selectorGetUserAddress = (store: Store): IGetLocationAddressByIdResponse | undefined => {
  return store.userRedux.userData?.address;
};

export const selectorGetUserSpendTime = (store: Store): number | undefined => {
  return store.userRedux.userData?.spendTime;
};
