import {ChangeEvent, FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {FieldBaseProps} from 'core/types';
import {IOrganisationTinyResponse} from '@joc/api-gateway/lib/api-client';
//helpers
import {getIsSelectedValueChanged, getNewOptions, getNewInputValue} from './helpers';
//context
import {useVolunteerInfoContext} from 'components/Volunteer/SignUpFormV/AboutYouSignupFormV/VolunteerInfoForm/context';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './index.module.scss';

type InputOrgGuestProps = FieldBaseProps & {placeholder?: string};

export const InputOrgGuest: FC<InputOrgGuestProps> = (fieldBaseProps) => {
  const {field, form} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const {t} = useTranslation(['common', 'inputs']);

  const {activeOrganisations, isOrganisationReadonly} = useVolunteerInfoContext();

  const [inputValue, setInputValue] = useState('');
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [options, setOptions] = useState<Array<IOrganisationTinyResponse>>([]);

  useEffect(() => {
    if (activeOrganisations && field.value) {
      const newInputValue = getNewInputValue(activeOrganisations, field.value);
      if (newInputValue) setInputValue(newInputValue);
    }
  }, [activeOrganisations, field.value]);

  useEffect(() => {
    if (activeOrganisations) {
      const newCurrentOptions = getNewOptions(activeOrganisations, inputValue);
      setOptions(newCurrentOptions);
    }
  }, [activeOrganisations, inputValue]);

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newInputValue = event.target.value;
    setInputValue(newInputValue);

    if (field.value && activeOrganisations) {
      const isSelectedValueChanged = getIsSelectedValueChanged(activeOrganisations, newInputValue, field.value);
      if (isSelectedValueChanged) setFieldValue(field.name, '');
    }
  };

  const inputFocusHandler = () => {
    setIsOptionsOpen(true);
  };

  const inputBlurHandler = () => {
    setFieldTouched(field.name, true);
    setIsOptionsOpen(false);
  };

  const optionClickHandler = (option: IOrganisationTinyResponse) => () => {
    setFieldValue(field.name, option.id);
    setIsOptionsOpen(false);
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.input__search}>
        <label className={styles.input__search_icon} htmlFor={field.name} />
        <input
          id={field.name}
          value={inputValue}
          placeholder={fieldBaseProps?.placeholder || t('inputs:placeholders.selectOrgSchool')}
          onChange={inputChangeHandler}
          onFocus={inputFocusHandler}
          onBlur={inputBlurHandler}
          disabled={isOrganisationReadonly}
        />
        {isOptionsOpen && !isOrganisationReadonly && (
          <div className={styles.dropWrap}>
            <div className={styles.dropContainer}>
              {options.length ? (
                options.map((option) => (
                  <div key={option.id} className={styles.dropDown} onMouseDown={optionClickHandler(option)}>
                    <div className={styles.dropDown_Item}>
                      <img
                        src={`${process.env.REACT_APP_S3_API}/${option.organizationLogoPath}`}
                        alt="logo organization"
                      />
                      {option.organizationName}
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.dropDown}>{t('common:noOrgFound')}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};

export default InputOrgGuest;
