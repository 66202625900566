export const urls = {
  main: '/',
  // Onboarding
  onboarding: '/onboarding',
  onboardingChooseUserType: '/onboarding/user-type',
  onboardingChooseStoreType: '/onboarding/store-type',
  onboardingLoginFromEmail: '/onboarding/login-from-email',
  onboardingRecoveryPassword: '/onboarding/recovery-password',
  onboardingGreeting: '/onboarding/greeting',
  onboardingLoginOrganization: '/onboarding/organization/login',
  onboardingOrgSignupAboutYou: '/onboarding/organization/signup/about-you',
  onboardingOrgSignupAboutOrganization: '/onboarding/organization/signup/about-organization',
  onboardingOrgSignupContacts: '/onboarding/organization/signup/contacts',
  onboardingOrgSignupInviteColleagues: '/onboarding/organization/signup/invite-colleagues',
  onboardingLoginVolunteer: '/onboarding/volunteer/login',
  onboardingSignupVolunteer: '/onboarding/volunteer/signup',
  onboardingSignupCongrat: '/onboarding/signup/congratulation',
  onboardingCongrat: '/onboarding/congratulation',
  onboardingConnectionError: '/onboarding/connection-error',
  onboardingConfirmInvite: '/onboarding/confirm-invite/:id',
  onboardingAcceptInviteCongrats: '/onboarding/accept-invite/congrats',
  onboardingAcceptInviteError: '/onboarding/accept-invite/error',
  onboardingClaimJoin: '/onboarding/claim-join',
  onboardingClaimPending: '/onboarding/claim-pending',
  onboardingClaimCongratulations: '/onboarding/claim-congratulations',
  // Shared
  about: '/onboarding/about',
  paymentSuccess: '/onboarding/payment-success',
  terms: '/onboarding/terms',
  policy: '/onboarding/policy',
  getInTouch: '/onboarding/get-in-touch',
  badGateway: '/onboarding/bad-gateway',
  accessDenied: '/onboarding/access-denied',
  tariffPlans: '/onboarding/pricing',
  newMainFeed: '/feed',
  landing: '/landing',
  verification: '/verify-manual-opportunity',
  publicDashboard: '/live-screen/:id',
  // Organization
  org: '/organization',
  orgDashboard: '/organization/dashboard',
  orgOpportunities: '/organization/opportunities',
  orgVolunteers: '/organization/volunteers',
  orgMarketing: '/organization/marketing',
  orgInbox: '/organization/inbox',
  orgStatistics: '/organization/statistics',
  orgEditAccount: '/organization/edit-account',
  orgEditOrg: '/organization/edit-organization',
  orgInviteColleagues: '/organization/invite-colleagues',
  orgMembers: '/organization/members',
  // Volunteer
  volunteer: '/volunteer',
  volFeed: '/volunteer/feed',
  volFeedViewPath: '/volunteer/feed/view/:id',
  volMyFeed: '/volunteer/my-feed',
  volDiploma: '/volunteer/diploma',
  volInbox: '/volunteer/inbox',
  volManualOpportunity: '/volunteer/manual-opportunity',
  volUpdateManualOpportunity: '/volunteer/update-manual-opportunity',
  volGetInTouch: '/volunteer/get-in-touch',
  volAbout: '/volunteer/about',
  volEditAccount: '/volunteer/edit-account',
  // volPaySuccess: '/volunteer/payment-success',
  liveScreen: '/live-screen',
};

export const MarketUrls = {
  AppStore: 'https://apps.apple.com/us/app/chesed-works/id1589218541',
  AppStoreAdmin: 'https://apps.apple.com/us/app/chesed-works-admin/id1667893097',
  PlayMarket: 'https://play.google.com/store/apps/details?id=com.volunteer_project',
  PlayMarketAdmin: 'https://play.google.com/store/apps/details?id=joc.admin.app&pli=1',
};
