import {FC} from 'react';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import PopupContainer from 'shared/components/PopupContainer';
//styles
import styles from './CookiePopup.module.scss';

type CookiePopupProps = {
  setIsShowPopup: () => void;
};

const CookiePopup: FC<CookiePopupProps> = ({setIsShowPopup}: CookiePopupProps) => {
  return (
    <PopupContainer setIsShowPopup={setIsShowPopup} isFixedStyle isDisablePadding isDisableContentMarginTop>
      <WhiteContainer title="COOKIE POLICY" titleStyles={{textAlign: 'center'}}>
        <div className={styles.cookie_policy}>
          <p className={styles.centered__text}>Updated at July 31st, 2024</p>

          <h2 className={styles.title}>Definitions and key terms</h2>

          <div className={styles.article__text}>
            To help explain things as clearly as possible in this Cookie Policy, every time any of these terms are
            referenced, are strictly defined as:
          </div>

          <ul>
            <li>
              Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify
              your browser, provide analytics, remember information about you such as your language preference or login
              information.
            </li>
            <li>
              Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Just One Chesed, that is
              responsible for your information under this Cookie Policy.
            </li>
            <li>
              Device: any internet connected device such as a phone, tablet, computer or any other device that can be
              used to visit Just One Chesed and use the services.
            </li>
            <li>
              Personal Data: any information that directly, indirectly, or in connection with other information —
              including a personal identification number — allows for the identification or identifiability of a natural
              person.
            </li>
            <li>
              Service: refers to the service provided by Just One Chesed as described in the relative terms (if
              available) and on this platform.
            </li>
            <li>
              Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and
              others who provide our content or whose products or services we think may interest you.
            </li>
            <li>You: a person or entity that is registered with Just One Chesed to use the Services.</li>
          </ul>

          <div className={styles.article__text}>
            This Cookie Policy was created with <a href="https://termify.io/">Termify</a>.
          </div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Introduction</h2>

          <div className={styles.article__text}>
            This Cookie Policy explains how Just One Chesed and its affiliates (collectively &ldquo;Just One
            Chesed&ldquo;, &ldquo;we&ldquo;, &ldquo;us&ldquo;, and &ldquo;ours&ldquo;), use cookies and similar
            technologies to recognize you when you visit our website/app, including without limitation justonechesed.org
            and any related URLs, mobile or localized versions and related domains / sub-domains
            (&ldquo;Websites&ldquo;). It explains what these technologies are and why we use them, as well as the
            choices for how to control them.
          </div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>What is a cookie?</h2>

          <div className={styles.article__text}>
            A cookie is a small text file that is stored on your computer or other internet connected device in order to
            identify your browser, provide analytics, remember information about you such as your language preference or
            login information. They&lsquo;re completely safe and can&lsquo;t be used to run programs or deliver viruses
            to your device.
          </div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Why do we use cookies?</h2>

          <div className={styles.article__text}>
            We use first party and/or third party cookies on our website/app for various purposes such as:
          </div>

          <ul>
            <li>To facilitate the operation and functionality of our website/app;</li>
            <li>To improve your experience of our website/app and make navigating around them quicker and easier;</li>
            <li>
              To allow us to make a bespoke user experience for you and for us to understand what is useful or of
              interest to you;
            </li>
            <li>To analyze how our website/app is used and how best we can customize it;</li>
            <li>To identify future prospects and personalize marketing and sales interactions with it;</li>
            <li>To facilitate the tailoring of online advertising to your interests.</li>
          </ul>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>What type of cookies does Just One Chesed use?</h2>

          <div className={styles.article__text}>
            Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you
            close your browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration
            dates are set in the cookies themselves; some may expire after a few minutes while others may expire after
            multiple years. Cookies placed by the website you’re visiting are called “first party cookies”.
          </div>

          <div className={styles.article__text}>
            Strictly Necessary cookies are necessary for our website/app to function and cannot be switched off in our
            systems. They are essential in order to enable you to navigate around the website/app and use its features.
            If you remove or disable these cookies, we cannot guarantee that you will be able to use our website/app.
          </div>

          <div className={styles.article__text}>We use the following types of cookies in our website/app:</div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Essential Cookies</h2>

          <div className={styles.article__text}>
            We use essential cookies to make our website/app work. These cookies are strictly necessary to enable core
            functionality such as security, network management, your cookie preferences and accessibility. Without them
            you wouldn&lsquo;t be able to use basic services. You may disable these by changing your browser settings,
            but this may affect how the Websites function.
          </div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Performance and Functionality Cookies</h2>

          <div className={styles.article__text}>
            These cookies are used to enhance the performance and functionality of our website/app but are non-essential
            to their use. However, without these cookies, certain functionality like videos may become unavailable or
            you would be required to enter your login details every time you visit the website/app as we would not be
            able to remember that you had logged in previously.
          </div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Analytics and Customization Cookies</h2>

          <div className={styles.article__text}>
            These cookies collect information that is used to help us understand how our website/app is being used or
            how effective our marketing campaigns are, or to help us customize our website/app for you.
          </div>

          <div className={styles.article__text}>
            We use cookies served by Google Analytics to collect limited data directly from end-user browsers to enable
            us to better understand your use of our website/app. Further information on how Google collects and uses
            this data can be found at:{' '}
            <a href="https://www.google.com/policies/privacy/partners/">
              https://www.google.com/policies/privacy/partners/
            </a>
            . You can opt-out of all Google supported analytics on our Websites by visiting:{' '}
            <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
          </div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Third Party Cookies</h2>

          <div className={styles.article__text}>
            Some cookies that have been set on our website/app are not set on a first party basis by Just One Chesed.
            The Websites can be embedded with content from third parties to serve advertising. These third party service
            providers may set their own cookies on your web browser. Third party service providers control many of the
            performance and functionality, advertising, marketing and analytics cookies described above. We do not
            control the use of these third party cookies as cookies can only be accessed by the third party that
            originally set them.
          </div>

          {/* ///////////////////////////// */}
          <h2 className={styles.title}>How you can manage cookies?</h2>

          <div className={styles.article__text}>
            Most browsers allow you to control cookies through their &lsquo;settings&lsquo; preferences. However, if you
            limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no
            longer be personalized to you. It may also stop you from saving customized settings like login information.
            Browser manufacturers provide help pages relating to cookie management in their products.
          </div>

          <div className={styles.article__text}>
            Browser manufacturers provide help pages relating to cookie management in their products. Please see below
            for more information.
          </div>

          <ul>
            <li>Google Chrome</li>
            <li>Internet Explorer</li>
            <li>Mozilla Firefox</li>
            <li>Safari (Desktop)</li>
            <li>Safari (Mobile)</li>
            <li>Android Browser</li>
            <li>Opera</li>
            <li>Opera Mobile</li>
          </ul>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Blocking and disabling cookies and similar technologies</h2>

          <div className={styles.article__text}>
            Wherever you&lsquo;re located you may also set your browser to block cookies and similar technologies, but
            this action may block our essential cookies and prevent our website/app from functioning properly, and you
            may not be able to fully utilize all of its features and services. You should also be aware that you may
            also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your
            browser. Different browsers make different controls available to you. Disabling a cookie or category of
            cookie does not delete the cookie from your browser, you will need to do this yourself from within your
            browser, you should visit your browser&lsquo;s help menu for more information.
          </div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Changes To Our Cookie Policy</h2>

          <div className={styles.article__text}>
            We may change our Service and policies, and we may need to make changes to this Cookie Policy so that they
            accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for
            example, through our Service) before we make changes to this Cookie Policy and give you an opportunity to
            review them before they go into effect. Then, if you continue to use the Service, you will be bound by the
            updated Cookie Policy. If you do not want to agree to this or any updated Cookie Policy, you can delete your
            account.
          </div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Your Consent</h2>

          <div className={styles.article__text}>
            By using our website/app, registering an account, or making a purchase, you hereby consent to our Cookie
            Policy and agree to its terms.
          </div>

          {/* ///////////////////////////// */}

          <h2 className={styles.title}>Contact Us</h2>

          <div className={styles.article__text}>
            Don&lsquo;t hesitate to contact us if you have any questions regarding our Cookie Policy.
          </div>

          <ul>
            <li>
              Via Email: <a href="mailto:info@justonechesed.org">info@justonechesed.org</a>
            </li>
          </ul>
        </div>
      </WhiteContainer>
    </PopupContainer>
  );
};

export default CookiePopup;
