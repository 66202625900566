import {Dispatch, FC, SetStateAction} from 'react';
import {ChannelPreviewUIComponentProps, ChatContextValue, useChatContext} from 'stream-chat-react';
import type {Channel, ChannelMemberResponse} from 'stream-chat';
import cx from 'classnames';
//redux
import {
  AttachmentType,
  ChannelType,
  CommandType,
  EventType,
  MessageType,
  ReactionType,
  UserType,
} from 'redux/chat-service/actions';
//functions
import {getChannelMembers, getChannelName, getDisplayedUnreadMessagesCount} from 'core/functions';
//helpers
import {getContentMessage, getDayStamp, getIsChannelSelected, getTimeStamp} from './helpers';
//components
import AvatarGroup from './AvatarGroup';
//styles
import './MessagingChannelPreview.scss';

type MessagingChannelPreviewProps = ChannelPreviewUIComponentProps & {
  channel: Channel;
  setIsCreating: Dispatch<SetStateAction<boolean>>;
  latestMessage?: string;
  unread?: number;
  setActiveChannel?: ChatContextValue['setActiveChannel'];
  setCreatedActiveChannel: Dispatch<any>;
};

const MessagingChannelPreview: FC<MessagingChannelPreviewProps> = ({
  channel,
  latestMessage,
  unread,
  setIsCreating,
  setCreatedActiveChannel,
}) => {
  const {
    channel: activeChannel,
    client,
    setActiveChannel,
  } = useChatContext<AttachmentType, ChannelType, CommandType, EventType, MessageType, ReactionType, UserType>();

  const members: Array<ChannelMemberResponse> = getChannelMembers(channel.state.members, client?.user?.id);
  const channelName = getChannelName(members, channel);
  const timeStamp = getTimeStamp(channel);
  const dayStamp = getDayStamp(channel);
  const contentMessage = getContentMessage(latestMessage);
  const isChannelSelected = getIsChannelSelected(channel?.id, activeChannel?.id);

  const previewChannelClickHandler = () => {
    setIsCreating(false);
    setCreatedActiveChannel(undefined);
    setActiveChannel(channel);
  };

  return (
    <div
      className={cx({
        'channel-preview__container selected': isChannelSelected,
        'channel-preview__container': !isChannelSelected,
      })}
      onClick={previewChannelClickHandler}
    >
      <AvatarGroup members={members} />

      <div className="channel-preview__content-wrapper">
        <div className="channel-preview__content-top">
          <p className="channel-preview__content-name">{channelName}</p>
          <p className="channel-preview__content-message">{contentMessage}</p>
        </div>

        <div className="channel-preview__content-bottom">
          <p className="channel-preview__content-time">
            {!!dayStamp &&
              (typeof dayStamp === 'string' ? (
                <span dir="auto">{dayStamp}</span>
              ) : (
                <>
                  <span>{dayStamp.day}</span>
                  <span dir="auto"> {dayStamp.month} </span>
                  <span>{dayStamp.year}</span>
                </>
              ))}
          </p>
          <p className="channel-preview__content-time">{timeStamp}</p>
          {!!unread && (
            <p className="channel-preview__unread-message-count">{getDisplayedUnreadMessagesCount(unread)}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagingChannelPreview;
