// react
import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
// other packages
import cx from 'classnames';
// components
import {HashtagAutocomplete} from '../HashtagAutocomplete';
import Select from 'shared/inputs/Select';
// types
import Checkbox from 'shared/components/Checkbox';
import {IHashtag} from 'redux/hashtag-service/types';
//formik
import {Field} from 'formik';
// styles
import styles from './index.module.scss';
import InputErrorHint from '../InputErrorHint';

interface IHashtagFieldProps {
  options: IHashtag[];
  isVolunteer?: boolean;
  isErrorArePresent?: boolean;
  isVisibleHashtagField?: boolean;
  handleChangeVisibleHashtag: (checked: boolean, _value?: string | undefined) => void;
  disabled?: boolean;
}

export const HashtagField: FC<IHashtagFieldProps> = ({
  isErrorArePresent,
  isVolunteer = false,
  isVisibleHashtagField,
  handleChangeVisibleHashtag,
  options,
  disabled,
}) => {
  const {t} = useTranslation(['form']);

  const [isLoggedInAsVolunteer, setIsVolunteer] = useState(false);

  useEffect(() => {
    setIsVolunteer(!!localStorage.getItem('volunteerId'));
  }, []);

  const remapToSelectOptions = (ops: Array<IHashtag>) =>
    ops.map((option) => ({title: `#${option.name}`, value: option.id}));

  return (
    <>
      <div className={styles.hashtag__wrapper}>
        <Checkbox
          disabled={disabled}
          changeHandle={handleChangeVisibleHashtag}
          defaultChecked={isVisibleHashtagField}
        />
        <p className={cx(isVolunteer ? styles.hashtag_label__volunteer : styles.hashtag_label__admin)}>
          {t('form:opportunity.doesOppoHaveHashtag')}
        </p>
      </div>
      {isVisibleHashtagField && (
        <div className={styles.hashtag_wrapper}>
          {isLoggedInAsVolunteer ? (
            <>
              <Field
                name="tagId"
                options={remapToSelectOptions(options)}
                disabled={!options.length || isErrorArePresent}
                placeholder="Select category"
                error={isErrorArePresent ? 'Please select Organization/School' : ''}
                component={Select}
                isDisabledMargin={isErrorArePresent}
                isSmallHeight
                isDisableHeight
              />
              {isErrorArePresent && (
                <div className={styles.error}>
                  <InputErrorHint errorText="Please select Organization/School" />
                </div>
              )}
            </>
          ) : (
            <Field
              name="tagId"
              label=""
              options={options}
              placeholder="Select category"
              component={HashtagAutocomplete}
              noOptionsText="No categories found"
              isErrorArePresent={isErrorArePresent}
            />
          )}
        </div>
      )}
    </>
  );
};
