import {FC} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
//images
import AppStore from 'assets/image/apple.svg';
import GooglePlay from 'assets/image/google.svg';
//redux
import {Store} from 'redux/root';
import {selectorGetUserData} from 'redux/user-service/selector';
//functions
import {getStoreLink} from 'core/functions/checkBrowser';
//hooks
import {useWindowSize} from 'hooks/useWindowSize';
//types
import {USER_ROLES} from '@joc/api-gateway';
//components
import Appeal from '../Appeal/DefaultRenderer';
//styles
import styles from './Banner.module.scss';

const Banner: FC<BannerPropsType> = ({userData}: BannerPropsType) => {
  const {t} = useTranslation('messages');
  const isHide = useWindowSize();

  if (isHide) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <Appeal styleThink>
        <h3>{t('bannerTitle')}</h3>
        {getStoreLink(window).link !== '#' && userData?.role?.roleName === USER_ROLES.VOLUNTEER && (
          <div>
            {t('bannerMessage')}
            {getStoreLink(window).os}
            <div className={styles.text}>
              <a href={getStoreLink(window).link}>
                {getStoreLink(window).os === 'iOS' ? (
                  <img src={AppStore} alt="AppStore" />
                ) : (
                  <img src={GooglePlay} alt="GooglePlay" />
                )}
              </a>
            </div>
          </div>
        )}
      </Appeal>
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  userData: selectorGetUserData(store),
});

const connector = connect(mapStateToProps);

type BannerPropsType = ConnectedProps<typeof connector>;

export default connector(Banner);
