import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//components
import ArrowBackLink from 'shared/components/ArrowBackLink';
import AllTariffs from 'components/Organization/AllTariffs';
//styles
import styles from './TariffPlans.module.scss';

const TariffPlans: FC = () => {
  const {t} = useTranslation('tariffPlans');
  const accessToken = localStorage.getItem('accessToken');

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {accessToken && <ArrowBackLink parentClassName={styles.nav} />}
        <h3 className={styles.title}>{t('pricing')}</h3>
      </div>

      <div className={styles.content__list}>
        <AllTariffs />
      </div>
    </div>
  );
};

export default TariffPlans;
