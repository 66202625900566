import {FC} from 'react';
import cx from 'classnames';
//styles
import styles from './OptionsSeparator.module.scss';
import {useTranslation} from 'react-i18next';

type OptionsSeparatorProps = {
  marginTopDisable?: boolean;
};

const OptionsSeparator: FC<OptionsSeparatorProps> = ({marginTopDisable}: OptionsSeparatorProps) => {
  const {t} = useTranslation(['messages']);

  return (
    <div className={cx(styles.main, marginTopDisable && styles.margin_disabled)}>
      <hr /> <span>{t('messages:or')}</span> <hr />
    </div>
  );
};

export default OptionsSeparator;
