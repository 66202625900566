import {FC, ReactNode, useEffect, useState} from 'react';
//components
import Logo from '../Logo';
import LangSwitch from '../HeaderWrapper/LangSwitch';
//styles
import styles from './index.module.scss';

type OnboardingContentContainerType = {
  children: ReactNode;
  disableOnboardingLogoLink?: boolean;
};

const OnboardingContentContainer: FC<OnboardingContentContainerType> = ({children, disableOnboardingLogoLink}) => {
  const [isGuest, setIsGuest] = useState(true);

  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem('accessToken');
    if (localStorageAccessToken) setIsGuest(false);
  }, []);

  return (
    <div className={styles.content__container}>
      <div className={styles.content__header}>
        <Logo isOnboarding={!disableOnboardingLogoLink && isGuest} />
        <LangSwitch />
      </div>
      <div className={styles.content__wrapper}>{children}</div>
    </div>
  );
};

export default OnboardingContentContainer;
