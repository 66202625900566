import {FC} from 'react';
//translation
import {useTranslation} from 'react-i18next';
//components
import ArrowBackLink from 'shared/components/ArrowBackLink';
import WhiteContainer from 'shared/components/WhiteContainer';
import TermsInfo from './TermsInfo';
// styles
import styles from './Terms.module.scss';

const Terms: FC = () => {
  const {t} = useTranslation('terms');
  return (
    <>
      <ArrowBackLink parentClassName={styles.nav} />
      <WhiteContainer title={t('title')}>
        <TermsInfo />
      </WhiteContainer>
    </>
  );
};

export default Terms;
