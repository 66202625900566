import {Dispatch, FC, SetStateAction, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
//context
import {OpportunitiesListContext} from '../../../OpportunitiesListContext';
//redux
import {Store} from 'redux/root';
import {
  selectorGetUserAddress,
  selectorGetUserVolunteerId,
  selectorGetUserVolunteerStatus,
} from 'redux/user-service/selector';
//functions
import {hasDatePassed, isVolunteerCantMakeIt} from 'core/functions';
//types
import {
  IOpportunityResponse,
  OPPORTUNITY_TYPES,
  SearchOpportunitiesRequest,
  OPPORTUNITY_STATUSES,
} from '@joc/api-gateway';
import {JOIN_STATE} from 'core/types';
//urls
import {urls} from 'core/appUrls';
//helpers
import {getIsJoinButtonDisabled, getIsVolunteerSuspended} from './helpers';
//constants
import {LinkCopiedSuccessfully, LINKS_URLS} from 'core/constants';
//components
import OpportunityActions from '../OpportunityActionsPopup';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import SnackbarSuccess from 'shared/components/Snackbars/SnackbarSuccess';
//styles
import styles from './Action.module.scss';
import {API} from 'core/API';
import {setError} from 'redux/error-service/action';
import {CANTMAKEIT, UPDATE_CURRENT_OPPORTUNITY, UPDATE_OPPORTUNITY} from 'redux/actions-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import {debounce} from 'lodash';

type ActionProps = {
  isAdmin?: boolean;
  resetClickHandler?: () => void;
  opportunity: IOpportunityResponse;
  setJoinState: Dispatch<SetStateAction<JOIN_STATE>>;
};

const Action: FC<ActionProps> = ({isAdmin, opportunity, setJoinState}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const {t} = useTranslation(['buttons', 'errors']);

  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

  const {isVolunteerOpportunities} = useContext(OpportunitiesListContext);

  const volunteerStatus = useSelector((store: Store) => selectorGetUserVolunteerStatus(store));
  const volunteerLocation = useSelector((store: Store) => selectorGetUserAddress(store));
  const userVolunteerId = useSelector((store: Store) => selectorGetUserVolunteerId(store));

  const isVolunteerSuspended = useMemo(() => getIsVolunteerSuspended(volunteerStatus), [volunteerStatus]);

  const isJoinButtonDisabled = useMemo(
    () =>
      getIsJoinButtonDisabled(
        isVolunteerSuspended,
        userVolunteerId,
        opportunity.volunteers,
        opportunity.opportunityActiveStatus?.status
      ),
    [isVolunteerSuspended, userVolunteerId, opportunity.volunteers]
  );

  const joinButtonTitle = useMemo(
    () =>
      isVolunteerCantMakeIt(opportunity.volunteers, userVolunteerId)
        ? t('buttons:button.rejoin')
        : t('buttons:button.join'),
    [opportunity.volunteers, userVolunteerId]
  );

  /**
   * TODO: When adding new logic, the changes have grown, need to rework it
   */
  const cantMakeItClickHandler = useCallback(async () => {
    try {
      if (userVolunteerId && opportunity?.id) {
        const {id} = opportunity;

        await API.toggleOpportunityVolunteerStatusCanMakeIt(id);

        dispatch({type: CANTMAKEIT, payload: {id, userVolunteerId}});

        const updatedOpportunity = await API.searchOpportunitiesByVolunteer(
          undefined,
          volunteerLocation ? `${volunteerLocation.latitude},${volunteerLocation.longitude}` : undefined,
          SearchOpportunitiesRequest.fromJS({
            opportunityIds: [id],
          })
        );

        const payloadData = {data: updatedOpportunity, opportunityId: id};

        dispatch({type: UPDATE_CURRENT_OPPORTUNITY, payload: payloadData});
      }
    } catch (error) {
      dispatch(
        setError(
          error?.response?.data?.message || error?.response?.message || error.message,
          error?.response?.data?.code || error?.response?.code || error.code
        )
      );
      setJoinState(JOIN_STATE.FAIL);
    }
  }, [userVolunteerId]);

  /**
   * TODO: When adding new logic, the changes have grown, need to rework it
   */

  const joinClickHandlerHelper = useCallback(async () => {
    if (!userVolunteerId) history.push(urls.onboardingLoginVolunteer);

    if (userVolunteerId && opportunity?.id) {
      try {
        await API.attachByVolunteer(opportunity?.id);

        const updatedOpportunity = await API.getOpportunityById(opportunity?.id);

        dispatch({type: UPDATE_OPPORTUNITY, payload: updatedOpportunity});

        setJoinState(JOIN_STATE.SUCCESS);
      } catch (error) {
        /**
         * TODO: Rework all the logic + make an error handler
         */
        dispatch(
          setError(
            error?.response?.data?.message || error?.response?.message || error.message,
            error?.response?.data?.code || error?.response?.code || error.code
          )
        );
        setJoinState(JOIN_STATE.FAIL);
      }
    }
  }, [userVolunteerId]);

  const viewButtonClickHandler = (opportunityId: number) => () =>
    history.push(`${LINKS_URLS.feedViewLink}/${opportunityId}`);

  const joinClickHandler = () => {
    if (!isButtonDisabled) {
      isVolunteerCantMakeIt(opportunity.volunteers, userVolunteerId)
        ? cantMakeItClickHandler()
        : joinClickHandlerHelper();
      setIsButtonDisabled(true);
    }

    debounce(() => {
      setIsButtonDisabled(false);
    }, 2000);
  };

  const isAdminManualOppo = useMemo(
    () => opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL && isAdmin,
    [opportunity]
  );

  return (
    <div className={styles.action}>
      <ButtonDefault
        title={t('buttons:button.view')}
        classList="secondary md"
        clickHandler={viewButtonClickHandler(opportunity?.id)}
      />
      {isVolunteerOpportunities ? (
        <>
          {!opportunity?.isInitial ? (
            <OpportunityActions
              isAdminManualOppo={isAdminManualOppo}
              opportunity={opportunity}
              setIsOpenSnackbar={setIsOpenSnackbar}
              joinClickHandler={cantMakeItClickHandler}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <ButtonDefault
          title={joinButtonTitle}
          classList="primary md"
          clickHandler={joinClickHandler}
          disabled={isJoinButtonDisabled || isButtonDisabled}
        />
      )}
      {isOpenSnackbar && (
        <SnackbarSuccess
          isOpenSnackbar={isOpenSnackbar}
          setIsOpenSnackbar={setIsOpenSnackbar}
          text={LinkCopiedSuccessfully}
        />
      )}
    </div>
  );
};

export default Action;
