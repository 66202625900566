// react
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
// other packages
import cx from 'classnames';
import i18n from 'components/translate';
//redux
import {
  changeStatusManualOpportunity,
  getCoordinators,
  getOpportunities,
  getOpportunitiesCount,
  refreshPendingVolunteers,
  resetOpportunities,
} from 'redux/opportunities-service/action';
import {useDispatch, useSelector} from 'react-redux';
import {resetError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
import {selectorGetFilter} from 'redux/filter-service/selector';
import {setFullTextSearch, setPaginationSkip, setSort} from 'redux/filter-service/actions';
import {
  selectorGetCoordinators,
  selectorGetOpportunitiesRecords,
  selectorGetOpportunitiesTotal,
} from 'redux/opportunities-service/selector';
import {selectorGetOrgActiveStatus, selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//hooks
import useEffectNoFirstMount from 'hooks/useEffectNoFirstMount';
import {useScrollTable} from 'hooks/useScrollTable';
//functions
import {
  convertTimeToHoursAndMinutes,
  formatDate,
  generateLocation,
  getDurationDetails,
  getImageType,
  getOpportunityImagePath,
} from 'core/functions';
import {tenthListItemSelector} from 'shared/components/Buttons/ButtonUpToTop/util';
//helpers
import {getIsRefreshRequired} from './helpers';
//types
import {
  ALL_OPPORTUNITY_VOLUNTEER_STATUS,
  GetVolunteerByIdResponse,
  IOpportunityResponse,
  ISearchOpportunitiesRequest,
  ISearchOpportunitiesSort,
  OPPORTUNITY_ACTIVE_STATUS,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
  ORGANISATION_ACTIVE_STATUS,
  SEARCH_OPPORTUNITIES_POSSIBLE_SORT,
  SEARCH_OPPORTUNITY_POSSIBLE_SEARCH,
  SortDirection,
} from '@joc/api-gateway';
import {TableType} from '../../Volunteers/Table/interfaces';
import {PaginationValues} from 'core/types';
//context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
//constants
import {ORGANIZATION_TABS, TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES} from 'core/constants';
import {DEFAULT_SCROLLABLE_CONTAINER} from 'shared/components/Buttons/ButtonUpToTop/constants';
//components
import Actions from './Actions';
import Loader from 'shared/components/Loader';
import Observer from 'shared/components/Observer';
import TableRow from 'shared/components/Table/TableRow';
import TableBody from 'shared/components/Table/TableBody';
import TableMain from 'shared/components/Table/TableMain';
import TableHeader from 'shared/components/Table/TableHead';
import ButtonUpToTop from 'shared/components/Buttons/ButtonUpToTop';
import MenuTable from 'components/Organization/TableMenu_new';
import {TableActionsPopup} from 'components/TableActionsPopup';
import ResponseFailure from 'shared/components/ResponseFailure';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import OpportunityNameCell from 'shared/components/Table/CellRenderers/OpportunityNameCell';
import PossibleVolunteersCell from 'shared/components/Table/CellRenderers/PossibleVolunteersCell';
import ConfirmRejectActions from 'shared/components/Table/CellRenderers/ManualOpportunityActions';
import TableHeadCellWithSort from 'shared/components/Table/TableHead/TableHeadCell/TableHeadCellWithSort';
//styles
import styles from './index.module.scss';

export interface IOppoInfo {
  oppoType: OPPORTUNITY_TYPES | null;
  oppoId: number | null;
}

const emptyListMessage = i18n.t('errors:couldntFindOpps');

type OpportunitiesTableParentProps = {
  sort?: ISearchOpportunitiesSort;
  requestBody?: ISearchOpportunitiesRequest | null;
};

const OpportunitiesTable: FC<OpportunitiesTableParentProps> = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation('popup');
  const {activeTab, generalTabClickHandler} = useOpportunitiesContext();
  const {bodyTable, headerTable, scrollHeader} = useScrollTable();

  const printRef = useRef<HTMLTableElement>(null);

  const opportunitiesRecords = useSelector(selectorGetOpportunitiesRecords);
  const opportunitiesTotal = useSelector(selectorGetOpportunitiesTotal);
  const orgStatus = useSelector(selectorGetOrgActiveStatus);
  const schoolId = useSelector(selectorGetSchoolId);
  const coordinators = useSelector(selectorGetCoordinators);
  const filter = useSelector(selectorGetFilter);
  const orgId = useSelector(selectorGetOrgId);
  const error = useSelector(selectorGetError);

  const [isLoading, setIsLoading] = useState(true);
  const [isPrintableTable, setIsPrintableTable] = useState(false);

  const getData = useCallback(async () => {
    if ((!!orgId && filter?.fullTextSearch?.value === undefined) || filter?.fullTextSearch?.value) {
      setIsLoading(true);
      await dispatch(getOpportunitiesCount(orgId.toString()));
      await dispatch(getOpportunities({...filter, organisationId: +orgId}));
      setIsLoading(false);
    }
  }, [
    filter?.pagination,
    filter?.sort,
    filter?.volunteerStatus,
    filter?.status,
    filter?.opportunityTypesList,
    filter?.withWho,
    filter?.weekDays,
    filter?.fullTextSearch,
    orgId,
  ]);

  useEffectNoFirstMount(() => getData(), [getData]);

  useEffect(() => {
    if (!coordinators.length) dispatch(getCoordinators(orgId));
  }, []);

  useEffect(() => {
    const isRefreshRequired = getIsRefreshRequired(opportunitiesRecords, activeTab);

    if (isRefreshRequired && orgId) {
      dispatch(getOpportunitiesCount(orgId.toString()));
      dispatch(refreshPendingVolunteers());
    }
  }, [opportunitiesRecords, activeTab]);

  const searchChangeHandler = (value: string): void => {
    const newValue = value === '' ? undefined : value;
    if (filter?.fullTextSearch?.value !== newValue) {
      dispatch(
        setFullTextSearch({
          value: newValue,
          fields: Object.values(SEARCH_OPPORTUNITY_POSSIBLE_SEARCH),
        })
      );
    }
  };

  const sortChangeHandler = (field: string): void => {
    const sort = {
      sortBy: field,
      sortDir: filter?.sort?.sortDir === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
    };
    resetHandler();
    dispatch(resetOpportunities());
    dispatch(setSort(sort));
  };

  const resetHandler = () => {
    dispatch(setPaginationSkip(0));
    resetError();
  };

  const paginationSkipHandler = ({skip}: PaginationValues) => dispatch(setPaginationSkip(skip));

  const confirmOpportunityHandler = (opportunityId: number) => () =>
    dispatch(changeStatusManualOpportunity(opportunityId, OPPORTUNITY_STATUSES.OPEN));
  const rejectOpportunityHandler = (opportunityId: number) => () =>
    dispatch(changeStatusManualOpportunity(opportunityId, OPPORTUNITY_STATUSES.CANCELED));

  const isSuspended = useMemo(() => orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED, [orgStatus]);
  const isPendingTab = useMemo(() => activeTab === ORGANIZATION_TABS.PENDING, [activeTab]);
  const isCallChildClasses = useMemo(
    () => (isPendingTab ? 'opportunities' : 'opportunities_without_action'),
    [isPendingTab]
  );

  const getTextDate = useCallback(
    (opportunity: IOpportunityResponse) => {
      const date = formatDate(opportunity.startDate);
      const checkIsManual = opportunity.opportunityType !== OPPORTUNITY_TYPES.MANUAL;
      const startDate = checkIsManual ? convertTimeToHoursAndMinutes(opportunity.startDate) : '';
      const endDate = checkIsManual ? convertTimeToHoursAndMinutes(opportunity.endDate) : '';

      if (startDate && endDate) {
        return `${date}\n${startDate}-${endDate}`;
      }

      return date;
    },
    [opportunitiesRecords]
  );

  const isSingleVolunteer = (volunteers: GetVolunteerByIdResponse[]) =>
    volunteers.filter((volunteer) => volunteer.status !== ALL_OPPORTUNITY_VOLUNTEER_STATUS.REJECT).length === 1;

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <MenuTable searchChangeHandler={searchChangeHandler}>
          <Actions
            printRef={printRef}
            setIsPrintableTable={setIsPrintableTable}
            isLoading={isLoading}
            requestBody={filter}
            disabled={isSuspended}
          />
        </MenuTable>
        {opportunitiesRecords.length ? (
          <TableMain className={styles.table__main} ref={printRef}>
            <div
              ref={headerTable}
              className={styles.table__header}
              onScroll={(event) => scrollHeader(event, TableType.Header)}
            >
              <TableHeader callChild={isCallChildClasses}>
                <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.avatar} text={TABLE_HEADERS.logo} />
                <TableHeadCellWithSort
                  itemClassName={TABLE_ITEMS_CLASSNAMES.chesedName}
                  text={TABLE_HEADERS.chesedName}
                  clickSortHandler={sortChangeHandler}
                  sortProps={{sort: filter?.sort, ownSortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.OpportunityName}}
                />
                <TableHeadCellWithSort
                  itemClassName={TABLE_ITEMS_CLASSNAMES.date}
                  text={TABLE_HEADERS.date}
                  clickSortHandler={sortChangeHandler}
                  sortProps={{sort: filter?.sort, ownSortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDay}}
                />
                <TableHeadCellWithSort
                  itemClassName={TABLE_ITEMS_CLASSNAMES.duration}
                  text={TABLE_HEADERS.duration}
                  clickSortHandler={sortChangeHandler}
                  sortProps={{sort: filter?.sort, ownSortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.Duration}}
                />
                <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.location} text={TABLE_HEADERS.location} />
                <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.hashtag} text={TABLE_HEADERS.hashtag} />
                <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator} text={TABLE_HEADERS.coordinator} />
                <TableHeadCell
                  itemClassName={TABLE_ITEMS_CLASSNAMES.volunteers}
                  text={schoolId ? TABLE_HEADERS.students : TABLE_HEADERS.volunteers}
                />
                {!isPrintableTable && opportunitiesRecords.every((el) => el.status !== OPPORTUNITY_STATUSES.CLOSED) && (
                  <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} />
                )}
              </TableHeader>
            </div>
            <TableBody ref={bodyTable} onScroll={scrollHeader} className={styles.table__body}>
              {opportunitiesRecords.map((opportunity: IOpportunityResponse, index: number) => {
                return (
                  <TableRow
                    className={tenthListItemSelector(index)}
                    key={opportunity.id}
                    callChild={isCallChildClasses}
                    suspend={
                      opportunity.opportunityActiveStatus.status === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED || isSuspended
                    }
                  >
                    <AvatarCell
                      imagePath={getOpportunityImagePath(opportunity)}
                      avatarTitle={t('opportunity.pictureOfOppo')}
                      popupType={getImageType(opportunity)}
                    />
                    <OpportunityNameCell
                      opportunityName={opportunity.opportunityName}
                      opportunityType={opportunity.opportunityType}
                      opportunityNameClass={styles.fullname}
                      adminId={opportunity.user?.id}
                      volunteers={opportunity.volunteers}
                      isVerified={opportunity.isVerificated}
                      opportunity={opportunity}
                    />
                    <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.date} text={getTextDate(opportunity)} />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.duration}
                      text={
                        opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL
                          ? getDurationDetails(opportunity.duration)
                          : ''
                      }
                    />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.location}
                      text={opportunity?.isVirtual ? opportunity.opportunityUrl : generateLocation(opportunity.address)}
                    />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.hashtag}
                      text={opportunity.tagId && opportunity.tag ? `#${opportunity.tag}` : ''}
                    />
                    <FullNameCell
                      firstName={opportunity.user?.firstName}
                      lastName={opportunity.user?.lastName}
                      disableTextStyle
                    />
                    <PossibleVolunteersCell
                      opportunity={opportunity}
                      volunteers={opportunity.volunteers}
                      opportunityId={opportunity.id}
                      isApprovalRequired={opportunity.isApprovalRequired && !isPendingTab}
                      opportunityName={opportunity.opportunityName}
                      opportunityStatus={opportunity.status}
                      opportunityType={opportunity.opportunityType}
                      disableButton={
                        opportunity.opportunityActiveStatus.status === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED ||
                        isSuspended
                      }
                    />
                    {!isPrintableTable && (
                      <>
                        <div
                          className={cx(
                            styles.confirm__actions_invisible,
                            isPendingTab && styles.confirm__actions_visible
                          )}
                        >
                          {(opportunity.status === OPPORTUNITY_STATUSES.PENDING ||
                            (opportunity.isApprovalRequired && isSingleVolunteer(opportunity.volunteers || []))) && (
                            <ConfirmRejectActions
                              confirmHandler={confirmOpportunityHandler(opportunity.id)}
                              rejectHandler={rejectOpportunityHandler(opportunity.id)}
                              disabled={
                                opportunity.opportunityActiveStatus.status === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED ||
                                isSuspended
                              }
                            />
                          )}
                        </div>
                        <TableActionsPopup
                          opportunity={opportunity}
                          isSuspended={isSuspended}
                          generalTabClickHandler={generalTabClickHandler}
                        />
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </TableMain>
        ) : isLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : error.state ? (
          <ResponseFailure styleTable message={error.message} buttonClickHandler={resetHandler} />
        ) : (
          <ResponseFailure styleTable message={emptyListMessage} buttonUnshow />
        )}
        {opportunitiesRecords.length !== opportunitiesTotal && (
          <Observer
            paginationSkip={opportunitiesRecords.length}
            setPagination={paginationSkipHandler}
            isLoading={isLoading}
          />
        )}
      </div>
      <ButtonUpToTop containerSelector={DEFAULT_SCROLLABLE_CONTAINER} />
    </div>
  );
};

export default OpportunitiesTable;
