import {FC, FocusEventHandler, FocusEvent, ChangeEventHandler, InputHTMLAttributes} from 'react';
//types
import {FieldBaseProps} from 'core/types/field';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import InputBase from './InputBase';

type InputProps = FieldBaseProps<
  Pick<InputHTMLAttributes<HTMLInputElement>, 'type' | 'placeholder' | 'min' | 'max' | 'onKeyPress'> & {
    isPencilHidden?: boolean;
    onFocus?: () => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    autocomplete?: string;
  }
>;

const Input: FC<InputProps> = ({type, placeholder, min, max, isPencilHidden, ...fieldBaseProps}) => {
  const {field, disabled, dir, onFocus, onKeyPress, onChange, onBlur, autocomplete, form} = fieldBaseProps;

  const {setFieldTouched, touched} = form;

  const onFocusHandler = () => {
    if (onFocus) onFocus();
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e);
    setFieldTouched(field.name);
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <InputBase
        {...field}
        id={field.name}
        className={fieldBaseProps.parentClassName}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onKeyPress={onKeyPress}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        min={min}
        max={max}
        isPencilHidden={isPencilHidden}
        dir={dir}
        autocomplete={autocomplete}
        {...(onChange ? {onChange} : {})}
      />
    </FieldWrapper>
  );
};
export default Input;
