import {FC, InputHTMLAttributes, useMemo} from 'react';
import cx from 'classnames';
//styles
import styles from './styles.module.scss';

type InputBaseProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'className'
  | 'id'
  | 'value'
  | 'placeholder'
  | 'type'
  | 'disabled'
  | 'min'
  | 'max'
  | 'onChange'
  | 'onBlur'
  | 'dir'
  | 'onKeyPress'
> & {
  isPencilHidden?: boolean;
  LeftIcon?: FC;
  onFocus?: () => void;
  autocomplete?: string;
};

const InputBase: FC<InputBaseProps> = ({
  className,
  id,
  value,
  disabled,
  isPencilHidden,
  LeftIcon,
  dir,
  ...otherProps
}) => {
  const isPencilVisible = useMemo(() => !!(value && !disabled && !isPencilHidden), [value, disabled, isPencilHidden]);

  return (
    <div className={cx(styles.container, className)} dir={dir}>
      {LeftIcon && <LeftIcon />}
      <input
        className={cx(styles.input, isPencilHidden && styles.input_pencilHidden)}
        id={id}
        autoComplete="off"
        value={value}
        disabled={disabled}
        {...otherProps}
        dir={dir}
      />
      {isPencilVisible && <label className={styles.edit} htmlFor={id} dir={dir} />}
    </div>
  );
};
export default InputBase;
