import i18n from 'i18next';
import moment from 'moment';
//types
import {
  IGroupsResponse,
  IOpportunityResponse,
  IRepeatableOpportunityRequest,
  OPPORTUNITY_STATUSES,
  RECURRENCE_FREQUENCY,
  RECURRENCE_POSSIBLE_UPDATE,
  RECURRENCE_WEEK_DAYS,
  RepeatableOpportunityResponse,
} from '@joc/api-gateway';
//constants
import {OPPORTUNITY_INITIAL_VALUES} from 'core/constants';
//functions
import {getIsEnglish} from 'core/functions';
import {RepeatOpportunityModalFormValues} from '../../OpportunityForm/OpportunityMainForm/RepeatOpportunity/RepeatOpportunityModal/formConfiguration';
import {getOpportunityValidTime} from 'shared/components/Pickers/CustomTimePicker/helpers';

//TODO add type of return functions's value IUpdateOpportunityRequest | ICreateOpportunityRequest
export const getPopUpdateInitialValues = (
  opportunityData: IOpportunityResponse,
  smartGroupsAll: IGroupsResponse[],
  userId?: number | undefined
) => {
  const {
    startDate,
    endDate,
    imagePath,
    opportunityName,
    opportunityDescription,
    isApprovalRequired,
    userMinAge,
    userMaxAge,
    user,
    withWho,
    opportunityType,
    opportunityPoints,
    volunteerRank,
    isVirtual,
    manualTimeTracking,
    address,
    opportunityUrl,
    repeatableOpportunity,
    additionalInformation,
    volunteersMaxCount,
    tagId,
    smartGroupId,
  } = opportunityData;

  const {startTime, endTime} = getOpportunityValidTime(startDate, endDate);

  const isSmartGroupExist = !!smartGroupsAll.find((smartGroup) => +smartGroup.id === +smartGroupId);

  return {
    imagePath,
    opportunityName,
    opportunityDescription,
    additionalInformation,
    isApprovalRequired: isApprovalRequired || false,
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    startTime,
    endTime,
    userMinAge,
    userMaxAge,
    user: user?.id ? +user?.id : userId,
    withWho: withWho || OPPORTUNITY_INITIAL_VALUES.withWho,
    opportunityType,
    opportunityPoints,
    volunteerRank,
    isVirtual,
    manualTimeTracking,
    address,
    opportunityUrl,
    smartGroupId: isSmartGroupExist ? smartGroupId : 1,
    repeatableOpportunity,
    isRecurrenceEnabled: !!repeatableOpportunity,
    volunteersMaxCount,
    tagId: !!tagId ? Number(tagId) : undefined,
    isPast: opportunityData.status === OPPORTUNITY_STATUSES.CLOSED,
  };
};

export const getFormattedDate = (date: string, time: string): string =>
  date &&
  moment(
    `${moment(date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('YYYY.MM.DD')}
${time}`,
    'YYYY.MM.DD HH:mm'
  ).format('YYYY.MM.DD HH:mm');

export const getDate = (formattedDate: string): string | undefined => {
  const currentLanguage = i18n.language;
  const isEnglish = getIsEnglish(currentLanguage);

  if (!isEnglish) moment.locale('en');

  const result = formattedDate
    ? moment(formattedDate, 'YYYY.MM.DD HH:mm').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)')
    : undefined;

  if (!isEnglish) moment.locale(currentLanguage);

  return result;
};

export const getFormattedRepeatedInfo = (
  repeatedValues: RepeatableOpportunityResponse
): IRepeatableOpportunityRequest | undefined => {
  if (!repeatedValues) return;

  const {daysOfWeek, frequency} = repeatedValues;

  const newWeekDays: Array<RECURRENCE_WEEK_DAYS> | undefined =
    daysOfWeek && frequency === RECURRENCE_FREQUENCY.WEEKLY ? daysOfWeek : undefined;

  return {
    ...repeatedValues,
    daysOfWeek: newWeekDays,
    frequency: frequency as RECURRENCE_FREQUENCY,
  };
};

export const getRecurrencePossibleUpdate = (
  repeatedValues: RepeatOpportunityModalFormValues,
  recurrencePossibleUpdate: RECURRENCE_POSSIBLE_UPDATE
): RECURRENCE_POSSIBLE_UPDATE | undefined => {
  if (!repeatedValues) return undefined;

  return recurrencePossibleUpdate || RECURRENCE_POSSIBLE_UPDATE.FUTURE;
};

export const getIsOpportunityClockDisabled = (opportunityData: IOpportunityResponse) => {
  return (
    opportunityData.manualTimeTracking &&
    (!!opportunityData.volunteers?.some((volunteer) => volunteer.opportunitySpendTime > 0) ||
      !!opportunityData.volunteers?.some((volunteer) => volunteer.clockInTime))
  );
};
