// react
import {useTranslation} from 'react-i18next';
import {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from 'react';
// components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
import {EditManualOppForm} from '../EditManualOppForm/EditManualOppForm';
import PopupMain from 'components/Organization/Opportunities/OpportunityPopups/PopupMain';
// redux
import {Store} from 'redux/root';
import {useDispatch, useSelector} from 'react-redux';
import {getAllHashtags} from 'redux/hashtag-service/actions';
import {selectorGetUserId} from 'redux/user-service/selector';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetHashtags} from 'redux/hashtag-service/selectors';
import {setIsNeedRefresh, updateAdminOpportunity} from 'redux/opportunities-service/action';
// hooks
import {useLoading} from 'hooks/useLoading';
import useThunkDispatch from 'hooks/useThunkDispatch';
// helper
import {getFormInitialValues, getVolunteerFullName} from './helper';
import {getDiffOpportunities} from 'core/functions/diffOportunities';
// config
import {EDIT_ADMIN_MANUAL_OPPORTUNITY_INIT_VALUES} from './config';
// formik
import {Formik} from 'formik';
// types
import {IOpportunityResponse, IUpdateOpportunityRequest} from '@joc/api-gateway';
// validation
import {opportunityAdminManualValidationSchema} from 'core/validation';
//styles
import styles from './index.module.scss';

interface IEditManualOppPopup {
  generalTabClickHandler?: () => void;
  opportunityData?: IOpportunityResponse;
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
}

export const EditManualOppPopup: FC<IEditManualOppPopup> = ({
  setIsOpenPopup,
  opportunityData,
  generalTabClickHandler,
}) => {
  const dispatch = useDispatch();
  const {startLoading} = useLoading();
  const thunkDispatch = useThunkDispatch();
  const orgId = localStorage.getItem('organisationId');
  const {t} = useTranslation(['errors', 'buttons', 'messages', 'form']);

  const orgHashtags = useSelector(selectorGetHashtags);
  const error = useSelector((store: Store) => store.errorRedux.error);
  const userId = useSelector((store: Store) => selectorGetUserId(store));

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [initValues, setInitValues] = useState<any>(EDIT_ADMIN_MANUAL_OPPORTUNITY_INIT_VALUES);
  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState<boolean>(!!opportunityData?.tagId);

  useEffect(() => {
    if (opportunityData?.id) {
      const editManualOppInitValues = getFormInitialValues(opportunityData, userId);
      setInitValues(editManualOppInitValues);
    }
  }, []);

  useEffect(() => {
    if (orgId) {
      getAllOrgHashtags(orgId);
    }
  }, [orgId]);

  const getAllOrgHashtags = async (orgId: string) => {
    await startLoading(getAllHashtags(orgId));
  };

  const handlerResetError = () => dispatch(resetError());

  const handleSubmit = async (values: any): Promise<void> => {
    setIsLoading(true);
    try {
      if (opportunityData?.id) {
        const editManualOppInitValues = getFormInitialValues(opportunityData, userId) as any;

        const diff = getDiffOpportunities<IUpdateOpportunityRequest & {endTime: number}>(
          values,
          editManualOppInitValues
        );

        await thunkDispatch(updateAdminOpportunity(opportunityData?.id, values, !!(diff.endTime || diff.startDate)));
        setIsUpdated(true);
      }
    } catch (error) {
      console.error(error);
      dispatch(setError(error?.response?.message.join(',') || error.message, error?.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessSubmitForm = () => {
    if (generalTabClickHandler) {
      generalTabClickHandler();
    } else {
      setIsOpenPopup(false);
    }

    dispatch(setIsNeedRefresh());
  };

  const handleIsOpenPopup = (isOpen: boolean) => {
    dispatch(setIsNeedRefresh());
    setIsOpenPopup(isOpen);
  };

  const volunteerName = useMemo(() => getVolunteerFullName(opportunityData), [getVolunteerFullName, opportunityData]);

  return (
    <PopupMain isDisablePadding setIsShowPopup={handleIsOpenPopup}>
      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonTitle={t('errors:checkAndTryAgain')}
          buttonClickHandler={handlerResetError}
        />
      ) : isUpdated ? (
        <CongratsPopup
          isFullHeight
          buttonTitle={t('buttons:button.done')}
          subtitle={t('messages:updatedManualChesedOppo')}
          doneButtonClickHandler={handleSuccessSubmitForm}
        />
      ) : (
        <Appeal styleContact>
          <div className={styles.title}>{t('form:updateManOppo.editTitle')}</div>
          {!!volunteerName && (
            <div className={styles.subtitle}>
              <span className={styles.subtitle__pre}>For:</span>
              {volunteerName}
            </div>
          )}
          <div className={styles.form__container}>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={opportunityAdminManualValidationSchema}
              validateOnChange
              onSubmit={(values) => {
                // @ts-ignore
                handleSubmit(values as any);
              }}
            >
              <EditManualOppForm
                options={orgHashtags}
                isLoading={isLoading}
                isVisibleHashtagField={isVisibleHashtagField}
                setIsVisibleHashtagField={setIsVisibleHashtagField}
                isPreviousCompleted={opportunityData?.isInitial}
              />
            </Formik>
          </div>
        </Appeal>
      )}
    </PopupMain>
  );
};
