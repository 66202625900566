import {
  ALL_OPPORTUNITY_VOLUNTEER_STATUS,
  DateRange,
  IOpportunityResponse,
  IOrganisationResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
  OpportunityResponse,
} from '@joc/api-gateway';
import {getDurationDetails} from 'core/functions';

export type OpportunitiesStartDayFrom = 'thisYear' | 'total';

//sorry for that, it's not my fault =)
export const getOpportunitiesStartDay = (
  opportunitiesStartDayFrom: OpportunitiesStartDayFrom,
  orgInfo: IOrganisationResponse | null
) => {
  const startYearDate = orgInfo?.school?.startYearDate;

  let fromDate: Date | undefined;

  if (startYearDate) {
    const localTimeZone = new Date().getTimezoneOffset();
    fromDate = new Date(startYearDate.getTime() + localTimeZone * 60000);
  } else {
    fromDate = undefined;
  }

  return opportunitiesStartDayFrom === 'thisYear' ? DateRange.fromJS({fromDate}) : undefined;
};

const foundPendingOppo = (oppo: OpportunityResponse) => {
  const {isApprovalRequired, status} = oppo;

  return isApprovalRequired && status === OPPORTUNITY_STATUSES.PENDING;
};

const foundRejectedOppo = (oppo: OpportunityResponse, volunteerId: number) => {
  const foundVolunteer = oppo.volunteers?.find((volunteer) => volunteer.id === String(volunteerId));

  return (
    foundVolunteer?.status === ALL_OPPORTUNITY_VOLUNTEER_STATUS.REJECT ||
    foundVolunteer?.status === ALL_OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT
  );
};

export const getFilteredOppo = (opportunities: OpportunityResponse[], volunteerId: number) => {
  return opportunities.filter((oppo) => !foundPendingOppo(oppo) && !foundRejectedOppo(oppo, volunteerId));
};

export const getVolunteerTimeSpend = (opportunity: IOpportunityResponse, volunteerId: number) => {
  const {volunteers: volunteersArePresent, duration: oppoDuration, opportunityType} = opportunity;

  const volunteerTimeSpent =
    volunteersArePresent?.find((volunteer) => volunteer.id === String(volunteerId))?.opportunitySpendTime || 0;

  /**
   *  it would be better to output '0h inside the function instead of outside it (getDurationDetails(oppoDuration) || '0h),
   *  but it is used in many other places, so it remains like this for now
   */

  return getDurationDetails(Number(volunteerTimeSpent)) || '0h';
};
