import {FC, Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Formik} from 'formik';
import moment from 'moment';
//redux
import {Store} from 'redux/root';
import {createManualOpportunity} from 'redux/opportunities-service/action';
import {resetError, setError} from 'redux/error-service/action';
//urls
import {urls} from 'core/appUrls';
//validation
import {opportunityManualValidationSchema} from 'core/validation';
//constants
import {OPPORTUNITIES_INITIAL_VALUES_MANUAL} from 'core/constants';
//types
import {OPPORTUNITY_TYPES} from '@joc/api-gateway/lib/api-client';
import {ManualOpportunityInitialValuesType} from 'core/types';
//components
import ResponseFailure from 'shared/components/ResponseFailure';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import CongratsPopup from 'components/Organization/CongratsPopup';
import ManualOpportunityMainForm from './ManualOpportunityMainForm';
//styles
import styles from './CreateManualOppoV.module.scss';

const CreateManualOppoV: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {t} = useTranslation(['errors', 'buttons', 'messages', 'form']);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState(false);

  const error = useSelector((store: Store) => store.errorRedux.error);
  const userVolunteerId = useSelector((store: Store) => store.userRedux.userData?.volunteerId);
  const userId = useSelector((store: Store) =>
    store.userRedux.userData?.id ? +store.userRedux.userData?.id : undefined
  );

  const dataPreset = {
    ...OPPORTUNITIES_INITIAL_VALUES_MANUAL,
    startDate: new Date(),
    endDate: new Date(),
    startTime: moment().add(1, 'hours').format('HH:mm'),
  };

  const [initValues, setInitValues] = useState({
    ...dataPreset,
    opportunityTypes: OPPORTUNITY_TYPES.MANUAL,
    volunteerId: userVolunteerId ? +userVolunteerId : 0,
    user: userId ? +userId : 0,
  });

  useEffect(() => {
    const values = localStorage.getItem('manual');
    if (values) setInitValues(JSON.parse(values));
  }, [error.state]);

  const submitClickHandler = async (values: ManualOpportunityInitialValuesType): Promise<void> => {
    if (!userVolunteerId) throw new Error(t('errors:cantFindVolunteerIdRefresh'));
    if (!userId) throw new Error(t('errors:cantFindUserIdRefresh'));
    setIsLoading(true);
    try {
      await dispatch(createManualOpportunity(values));
      setIsSubmitted(true);
    } catch (err: any) {
      dispatch(setError(err?.response?.message || err.message, err?.response?.code || err.code));
    } finally {
      setIsLoading(false);
    }
  };

  const handlerResetError = () => dispatch(resetError());

  return (
    <Fragment>
      <ArrowBackLink parentClassName={styles.nav} />
      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonTitle={t('errors:checkAndTryAgain')}
          buttonClickHandler={handlerResetError}
        />
      ) : isSubmitted ? (
        <CongratsPopup
          buttonTitle={t('buttons:button.done')}
          subtitle={t('messages:createdManualChesedOppo')}
          doneButtonClickHandler={() => history.push(urls.volMyFeed)}
        />
      ) : (
        <Appeal styleContact>
          <div className={styles.title}>{t('form:proposeManOppo.title')}</div>
          <div className={styles.form__container}>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={opportunityManualValidationSchema}
              onSubmit={(values) => submitClickHandler(values as any)}
            >
              <ManualOpportunityMainForm
                isLoading={isLoading}
                isVisibleHashtagField={isVisibleHashtagField}
                setIsVisibleHashtagField={setIsVisibleHashtagField}
              />
            </Formik>
          </div>
        </Appeal>
      )}
    </Fragment>
  );
};

export default CreateManualOppoV;
