// react
import {FC, useMemo} from 'react';
// other packages
import cx from 'classnames';
//functions
import {setVolunteerStatus} from 'core/functions';
//types
import {ALL_OPPORTUNITY_VOLUNTEER_STATUS, IOpportunityResponse, OPPORTUNITY_ACTIVE_STATUS} from '@joc/api-gateway';
//components
import ClockAction from './ClockAction';
import {VolunteerStatusIcon} from './VolunteerStatusIcon';
// helper
import {
  checkClockIconVisibility,
  checkingClockedStatus,
  getClosedStatusData,
  getStatusStyles,
  getStatusText,
  getVolunteerOpportunityStatus,
} from './helper';
//styles
import styles from './VolunteerStatus.module.scss';

interface IVolunteerStatusProps {
  isAdminOppo?: boolean;
  isCompletedTab?: boolean;
  userVolunteerId?: string;
  isChoiceIsAllowed: boolean;
  itemValue: IOpportunityResponse;
}

const VolunteerStatus: FC<IVolunteerStatusProps> = ({
  isAdminOppo,
  isChoiceIsAllowed,
  itemValue: oppoData,
  isCompletedTab = false,
  userVolunteerId: volunteerId,
}) => {
  const {opportunityActiveStatus, endDate: oppoEndDate, id, volunteers, manualTimeTracking} = oppoData;

  const isClockedIn = useMemo(() => checkingClockedStatus(volunteers, volunteerId), [volunteers, volunteerId]);

  const isSuspended = useMemo(
    () => opportunityActiveStatus.status === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED,
    [opportunityActiveStatus]
  );

  const volunteerOppoStatus = useMemo(
    () => getVolunteerOpportunityStatus(oppoData, volunteerId),
    [oppoData, volunteerId]
  );

  const oppoStatusVision = useMemo(
    () => setVolunteerStatus(volunteerOppoStatus, oppoEndDate),
    [volunteerOppoStatus, oppoEndDate]
  );

  const closedStatusData = useMemo(
    () => getClosedStatusData(volunteerOppoStatus, oppoData),
    [oppoData, volunteerOppoStatus, isAdminOppo]
  );

  const oppoStatusText = useMemo(
    () => getStatusText(isCompletedTab, closedStatusData, oppoStatusVision, volunteerOppoStatus),
    [isCompletedTab, closedStatusData, oppoStatusVision]
  );

  const isVisibleClockAction = useMemo(
    () => checkClockIconVisibility(oppoData, volunteerOppoStatus, closedStatusData, isSuspended),
    [oppoData, volunteerOppoStatus, closedStatusData, isSuspended]
  );

  const isCompletedTabStyles = isCompletedTab && volunteerOppoStatus !== ALL_OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT;

  const combinedClassNames = cx(
    styles.status,
    getStatusStyles(volunteerOppoStatus, styles),
    isCompletedTabStyles && styles.completed,
    !isCompletedTab && closedStatusData.isClosed && styles.closed
  );

  return (
    <div className={styles.feed_status}>
      <div className={combinedClassNames}>
        <VolunteerStatusIcon
          icon={oppoStatusVision.src}
          isCompletedTab={isCompletedTab}
          volunteerOppoStatus={volunteerOppoStatus}
          iconVisible={!closedStatusData.isClosedIconVisible}
        />
        <div className={styles.status__text}>{oppoStatusText}</div>
      </div>
      {isVisibleClockAction && manualTimeTracking && (
        <ClockAction isDisabled={!isChoiceIsAllowed} isClockedIn={isClockedIn} opportunityId={id} />
      )}
    </div>
  );
};

export default VolunteerStatus;
