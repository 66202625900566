import {IOrganisationTinyResponse} from '@joc/api-gateway/lib/api-client';

export const getNewOptions = (activeOrganisations: Array<IOrganisationTinyResponse>, inputValue: string) =>
  activeOrganisations.filter((organisation) =>
    organisation.organizationName.trim().toLowerCase().includes(inputValue.trim().toLowerCase())
  );

export const getNewInputValue = (activeOrganisations: Array<IOrganisationTinyResponse>, fieldValue: string) =>
  activeOrganisations.find((organisation) => organisation.id === fieldValue)?.organizationName;

export const getIsSelectedValueChanged = (
  activeOrganisations: Array<IOrganisationTinyResponse>,
  inputValue: string,
  fieldValue: string
) => inputValue.trim() !== activeOrganisations.find((organisation) => organisation.id === fieldValue)?.organizationName;
