import {FC, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';

import {API} from '../../core/API';
import {DashboardSettingsResponse} from '@joc/api-gateway';

import LogoImage from 'assets/image/logo2.svg';
import UserIcon from 'assets/image/user-icon.svg';

import styles from './LiveScreen.module.scss';
import {Link} from 'react-router-dom';
import {urls} from '../../core/appUrls';

export const LiveScreenPage: FC = () => {
  const [dashboard, setDashboard] = useState<DashboardSettingsResponse>();
  const getFileType = (fileName?: string) =>
    (fileName && (fileName.split('.').pop() === 'mp4' ? 'video' : 'image')) || '';

  const getDashboard = async () => {
    const data = await API.getDashboard();
    setDashboard(data);
  };

  useEffect(() => {
    getDashboard().then();
  }, []);

  const volunteerName = useMemo(
    () => dashboard?.recentlyVolunteerName.split(' ')[0],
    [dashboard?.recentlyVolunteerName]
  );
  const volunteerLastName = useMemo(
    () => dashboard?.recentlyVolunteerName.split(' ')[1],
    [dashboard?.recentlyVolunteerName]
  );

  return (
    <div className={styles.main}>
      <section className={styles.liveScreen}>
        <div className={styles.liveScreen__wrapper}>
          <div className={styles.liveScreen__header}>
            <div className={styles['liveScreen__header-logo']}>
              <img className={styles['liveScreen__header-logo--image']} src={LogoImage} alt="avatar" />
              <Link className={styles['liveScreen__header-logo--name']} to={urls.main}>
                Just One Chesed
              </Link>
            </div>
            <div className={styles.liveScreen__header__title}>
              <div className={styles['liveScreen__header__title-wrapper']}>
                <span className={styles['liveScreen__header__title-text']}>{dashboard?.title}</span>
              </div>
            </div>
          </div>
          <div className={cx(styles.liveScreen__card, styles['liveScreen__card-totalHours'])}>
            <span className={styles['liveScreen__card-title']}>World wide hours</span>
            <span className={styles['liveScreen__card-count']}>{8623}</span>
          </div>
          <div className={cx(styles.liveScreen__card, styles['liveScreen__card-totalOpportunities'])}>
            <span className={styles['liveScreen__card-title']}>Total Opportunities</span>
            <span className={styles['liveScreen__card-count']}>{4227}</span>
          </div>
          <div className={cx(styles.liveScreen__card, styles['liveScreen__card-recentlyVolunteer'])}>
            <span className={styles['liveScreen__card-title']}>Volunteer spotlight</span>
            {dashboard && dashboard.recentlyVolunteerName ? (
              <div className={styles.liveScreen__volunteer}>
                {/* {dashboard.recentlyVolunteerAvatar ? (
                  <img
                    className={styles.liveScreen__volunteer__avatar}
                    src={dashboard.recentlyVolunteerAvatar}
                    alt="volunteer avatar"
                  />
                ) : (
                  <img className={styles.liveScreen__volunteer__avatar} src={UserIcon} alt="volunteer avatar" />
                )} */}
                <div className={styles.liveScreen__volunteer__avatar}>
                  {volunteerName?.[0]} {volunteerLastName?.[0]}
                </div>
                {/* <div className={styles.liveScreen__volunteer__info}>
                  <span className={styles.liveScreen__volunteer__name} title={dashboard.recentlyVolunteerName}>
                    {dashboard.recentlyVolunteerName}
                  </span>
                </div> */}
              </div>
            ) : null}
            {dashboard && dashboard.recentlyOppoName ? (
              <span
                className={cx(styles['liveScreen__card-subtitle'], {
                  [styles.liveScreen__volunteer__oppo_padding]: dashboard.recentlyVolunteerName.trim().length >= 43,
                  [styles.liveScreen__volunteer__oppo]: dashboard.recentlyOppoName.trim().length >= 38,
                })}
                title={dashboard.recentlyOppoName}
              >
                <span className={styles['liveScreen__card-subtitle_bold']}>Opportunity: </span>
                <span>{dashboard.recentlyOppoName}</span>
              </span>
            ) : null}
          </div>
          <div className={styles.liveScreen__banner}>
            {dashboard?.mediaPath ? (
              <>
                {getFileType(dashboard?.mediaPath) === 'video' ? (
                  <video className={styles.liveScreen__banner__media__file} muted autoPlay playsInline loop>
                    <source src={dashboard?.mediaPath} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className={styles['liveScreen__banner__media-file']}
                    src={dashboard?.mediaPath}
                    alt="dashboard media"
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.liveScreen__footer}>
            <img src={LogoImage} className={styles['liveScreen__footer-logo']} alt="logo" />
            <div className={styles.liveScreen__footer__sutTitle}>
              <span className={styles['liveScreen__footer__sutTitle-text']}>{dashboard?.subTitle}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
