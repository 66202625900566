import {createRef, FC, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18next from 'components/translate';
import cx from 'classnames';
//redux
import {selectorGetOrgSchoolGrade} from 'redux/organization-service/selector';
//assets
import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
//hooks
import {useClickOutside} from 'core/customHooks';
//types
import {FieldBaseProps} from 'core/types';
import {GradeResponse} from '@joc/api-gateway';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './index.module.scss';

type GenderSelectProps = FieldBaseProps<{
  placeholder?: string;
  classList?: Array<string>;
}>;

const GradeSelect: FC<GenderSelectProps> = ({
  placeholder = i18next.t('inputs:placeholders.selectGrade'),
  classList,
  ...fieldBaseProps
}) => {
  const {field, form} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const {t} = useTranslation(['common', 'form']);

  const schoolGrade = useSelector(selectorGetOrgSchoolGrade);

  const title = useMemo(() => {
    if (field.value) {
      return `${t(`common:ordinal.${field.value}` as unknown as TemplateStringsArray)} ${t('form:schoolGrade')}`;
    }
    return '';
  }, [field.value]);

  const gradeRef = useMemo(() => schoolGrade?.map(() => createRef<HTMLDivElement>()), [schoolGrade]);

  const {isComponentVisible, componentRef, setIsComponentVisible} = useClickOutside({
    initialVisible: false,
    setFieldTouched,
    fieldName: field.name,
  });

  const gradeClickHandler = (id: number): void => {
    setFieldValue(field.name, id);
    setIsComponentVisible(false);
  };

  const titleClickHandler = () => {
    if (!field.value) return setIsComponentVisible(!isComponentVisible);
    const valueIndex = schoolGrade?.findIndex((i) => i.id.toString() === field.value.toString());
    const target = gradeRef && valueIndex && gradeRef[valueIndex].current;
    if (target)
      // set timeout cause in a first time the scroll doesn't work
      setTimeout(() => {
        target.scrollIntoView();
      }, 0);

    if (isComponentVisible) setFieldTouched(field.name, true);
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div
        id={field.name}
        className={cx(styles.dropdown_select, styles.position, {
          [styles.md]: classList?.includes('md'),
          [styles.dropdown_select__active]: isComponentVisible,
        })}
        ref={componentRef}
      >
        <div
          className={cx(styles.dropdown_select__title, {
            [styles.dropdown_select__title_selected]: !!field.value,
          })}
          onClick={titleClickHandler}
        >
          <span>{title || placeholder}</span>
          {!isComponentVisible ? (
            <BlueArrow className={styles.dropdown_select__title__vector} />
          ) : (
            <WhiteArrow className={styles.dropdown_select__title__vector} />
          )}
        </div>
        <div className={styles.dropdown_select__options}>
          {isComponentVisible &&
            schoolGrade &&
            schoolGrade.map((grade: GradeResponse) => (
              <div
                key={grade.id}
                className={styles.dropdown_select__options__item}
                onClick={() => gradeClickHandler(+grade.id)}
              >
                {t(`common:ordinal.${grade.id}` as unknown as TemplateStringsArray)} {t('form:schoolGrade')}
              </div>
            ))}
        </div>
      </div>
    </FieldWrapper>
  );
};

export default GradeSelect;
