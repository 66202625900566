import {FC} from 'react';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//images
import Logout from 'assets/image/logout.svg';
import UserSvg from 'assets/image/user-circle.svg';
import HeartSvg from 'assets/image/heart.svg';
import MailSvg from 'assets/image/mail.svg';
//redux
import {Store} from 'redux/root';
import {selectorGetUserRoles, selectorGetUserRolesByOrg, selectorIsUserMarketer} from 'redux/user-service/selector';
import {selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//types
import {VOLUNTEER} from '@joc/api-gateway';
//urls
import {urls} from 'core/appUrls';
//helpers
import {getPublicDashboardUrl} from './helpers';
//components
import AccountMenuDropDown from 'shared/components/AccountMenuDropDown';
import AccountMenuDropdownItem from 'shared/components/AccountMenuDropDown/AccountMenuDropdownItem';
import HeaderWrapper from 'shared/components/HeaderWrapper';
import NavBarVisibilityButton from './NavBarVisibilityButton';

const HeaderOrg: FC<HeaderOrgProps> = ({userRoles}) => {
  const {t, i18n} = useTranslation('header');

  const orgId = useSelector(selectorGetOrgId);
  const schoolId = useSelector(selectorGetSchoolId);
  const isMarketer = useSelector(selectorIsUserMarketer);
  const userRolesByOrg = useSelector(selectorGetUserRolesByOrg);

  const publicDashboardUrl = getPublicDashboardUrl(orgId, i18n.language, !!schoolId ? 'school' : 'org');

  const dropdown = (
    <AccountMenuDropDown>
      <AccountMenuDropdownItem link={urls.orgEditAccount} text={t('org.editMyAccount')} img={UserSvg} />
      {userRolesByOrg?.find((role) => role.roleName === VOLUNTEER.ADMIN || role.roleName === VOLUNTEER.MARKETER) && (
        <AccountMenuDropdownItem link={urls.orgEditOrg} text={t('org.editOrgInfo')} img={UserSvg} />
      )}
      {!isMarketer && (
        <AccountMenuDropdownItem link={urls.orgInviteColleagues} text={t('org.inviteColleagues')} img={UserSvg} />
      )}
      <AccountMenuDropdownItem link={publicDashboardUrl} text={t('org.liveScreen')} img={UserSvg} isBlank />
      <AccountMenuDropdownItem link={urls.about} text={t('org.aboutUs')} img={HeartSvg} />
      <AccountMenuDropdownItem link={urls.tariffPlans} text={t('org.pricing')} img={UserSvg} />
      <AccountMenuDropdownItem link={urls.getInTouch} text={t('org.getInTouch')} img={MailSvg} />
      <AccountMenuDropdownItem logout text={t('org.logout')} img={Logout} />
    </AccountMenuDropDown>
  );

  return (
    <HeaderWrapper chatLink={urls.orgInbox} dropdown={dropdown}>
      <NavBarVisibilityButton />
    </HeaderWrapper>
  );
};

const mapStateToProps = (store: Store) => ({
  userRoles: selectorGetUserRoles(store),
});

const connector = connect(mapStateToProps);

type HeaderOrgProps = ConnectedProps<typeof connector>;

export default connector(HeaderOrg);
