import {Dispatch, FC, Fragment, SetStateAction, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import qs from 'qs';
//redux
import {selectorGetOrgGeneralType, selectorGetOrgId} from 'redux/organization-service/selector';
import {createOpportunity, getOpportunitiesCount} from 'redux/opportunities-service/action';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
import {Store} from 'redux/root';
import {selectorGetUserId} from 'redux/user-service/selector';
//types
import {IOpportunityResponse} from '@joc/api-gateway';
import {OpportunityInitialValuesType} from 'core/types';
//hooks
import useThunkDispatch from 'hooks/useThunkDispatch';
//helpers
import {getOpportunityFromValues, getOpportunityInitialValues} from './helpers';
//components
import ResponseFailure from 'shared/components/ResponseFailure';
import PopupUpdate from '../PopupUpdate';
import OpportunityForm from '../../OpportunityForm';
import PopupCongrats from '../PopupCongrats';

type PopupCreateProps = {
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
  isSmartGroupMode?: boolean;
};

const PopupCreate: FC<PopupCreateProps> = ({setIsShowPopup, isSmartGroupMode}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const thunkDispatch = useThunkDispatch();
  const {t} = useTranslation(['popup', 'errors']);

  const error = useSelector(selectorGetError);
  const orgGeneralType = useSelector(selectorGetOrgGeneralType);
  const organisationId = useSelector(selectorGetOrgId).toString();
  const userId = useSelector((store: Store) => selectorGetUserId(store));

  const groupId = qs.parse(location.search, {ignoreQueryPrefix: true})?.groupId as string | undefined;

  const initialData: OpportunityInitialValuesType = getOpportunityInitialValues(
    !!isSmartGroupMode,
    groupId,
    orgGeneralType,
    userId
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isShowUpdatePopup, setIsShowUpdatePopup] = useState(false);
  const [formValues, setFormValues] = useState<OpportunityInitialValuesType>(initialData);
  const [createdOpportunityData, setCreatedOpportunityData] = useState<IOpportunityResponse>();

  const submitClickHandler = async (values: OpportunityInitialValuesType) => {
    setIsLoading(true);
    try {
      setFormValues(values as OpportunityInitialValuesType);
      const convertedValues = getOpportunityFromValues(values);
      const opportunityResponse = await thunkDispatch(createOpportunity({...convertedValues, organisationId}));
      setCreatedOpportunityData(opportunityResponse);
      setIsLoading(false);
      setIsSubmitted(true);
      dispatch(getOpportunitiesCount(organisationId));
      if (!!convertedValues.repeatableOpportunity) window.location.reload();
    } catch (error) {
      setIsLoading(false);
      thunkDispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
    }
  };

  const handleCreateOneMoreClick = () => {
    setFormValues(initialData);
    setIsSubmitted(false);
  };

  if (error.state)
    return (
      <ResponseFailure
        styleTable
        message={error.message ? error.message : t('errors:errorPlease')}
        buttonClickHandler={() => dispatch(resetError())}
      />
    );

  if (isSubmitted)
    return (
      <PopupCongrats
        setIsShowPopup={setIsShowPopup}
        setIsSubmited={setIsSubmitted}
        setIsShowUpdatePopup={setIsShowUpdatePopup}
        createOneMoreClickHandler={handleCreateOneMoreClick}
      />
    );

  if (isShowUpdatePopup && createdOpportunityData)
    return (
      <PopupUpdate
        setIsShowCreateOpportunityPopup={setIsShowPopup}
        setIsShowUpdateOpportunityPopup={setIsShowUpdatePopup}
        opportunityData={createdOpportunityData}
        setOpportunityData={setCreatedOpportunityData}
      />
    );

  return (
    <>
      <h2 className="title">{t('popup:opportunity.createANewOpportunity')}</h2>
      <OpportunityForm isLoading={isLoading} initialValues={formValues} submitClickHandler={submitClickHandler} />
    </>
  );
};

export default PopupCreate;
