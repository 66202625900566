import {BaseSyntheticEvent, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import cx from 'classnames';
//images
import ArrowSvg from 'assets/image/button-arrow-dark.svg';
//formik
import {Field, FieldProps, Form, FormikErrors, FormikTouched, useFormikContext} from 'formik';
//redux
import {selectorGetHashtags} from 'redux/hashtag-service/selectors';
import {getAllHashtags} from 'redux/hashtag-service/actions';
import {selectorGetPossibilityToInvite} from 'redux/organization-service/selector';
//types
import {OPPORTUNITY_TYPES, IOpportunityResponse, USER_ROLES} from '@joc/api-gateway';
import {IUpdateOpportunityInitialValues, OpportunityInitialValuesType} from 'core/types';
//functions
import {changeVisibilityAdditionalOpportunityMainForm} from 'core/functions';
//constants
import {ageSelectOptions} from 'core/constants/selectOptions';
//helpers
import {SwitcherFieldName} from './helpers';
import {PastOppoContext} from 'components/Organization/Volunteers/VolunteersPopups/VolunteerPastOpportunitiesPopup';
//components
import Input from 'shared/inputs/Input';
import TextArea from 'shared/inputs/TextArea';
import Loader from 'shared/components/Loader';
import Checkbox from 'shared/components/Checkbox';
import RepeatOpportunity from './RepeatOpportunity';
import TextEditor from 'shared/components/TextEditor';
import UploadPhoto from 'components/Volunteer/UploadPhoto';
import InputErrorHint from 'shared/components/InputErrorHint';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import WithWhoFilter from 'shared/components/FiltersFields/WithWhoFilter';
import SelectDropdown from 'shared/components/SelectDropdown/SingleSelect';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import RepeatedActionTypeModal from 'shared/components/RepeatedActionTypeModal';
import SmartGroupSelect from 'shared/components/SelectDropdown/SmartGroupSelect';
import CoordinatorSelect from 'shared/components/SelectDropdown/CoordinatorSelect';
import {TimePicker} from 'shared/components/Pickers/NewUI/TimePicker';
import {DatePicker} from 'shared/components/Pickers/NewUI/DatePicker';
// hooks
import {useLoading} from 'hooks/useLoading';
//styles
import styles from './OpportunityMainForm.module.scss';
import {HashtagField} from 'shared/components/HashtagField';
import {selectorGetUserRoles} from 'redux/user-service/selector';

export type FormikOpportunityMainForm = {
  // TODO: to determine values type
  errors: FormikErrors<IUpdateOpportunityInitialValues | OpportunityInitialValuesType>;
  touched: FormikTouched<IUpdateOpportunityInitialValues | OpportunityInitialValuesType>;
  values: any;
  dirty: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
  handleSubmit?: () => void;
};

interface OpportunityMainFormParentsProps {
  isVisibleHashtagField?: boolean;
  setIsVisibleHashtagField?: Dispatch<SetStateAction<boolean>>;
  isEdit?: boolean;
  isLoading: boolean;
  isRepeated?: boolean;
  statusClose?: boolean;
  schoolId: number | undefined;
  isManual: boolean | undefined;
  isPrivateOpportunity: boolean;
  isVisibleVolunteerLimit?: boolean;
  opportunityData?: IOpportunityResponse;
  manualTimeTracking: boolean | undefined;
  isVirtualOpportunity: boolean | undefined;
  setIsPrivateOpportunity: Dispatch<SetStateAction<boolean>>;
  setIsVisibleVolunteerLimit?: Dispatch<SetStateAction<boolean>>;
  setManualTimeTracking: Dispatch<SetStateAction<boolean | undefined>>;
  setIsVirtualOpportunity: Dispatch<SetStateAction<boolean | undefined>>;
  isOpportunityTimeDisable?: boolean;
  isOpportunityDateDisable?: boolean;
}

const OpportunityMainForm: FC<OpportunityMainFormParentsProps> = ({
  isManual,
  schoolId,
  isLoading,
  statusClose,
  isEdit = false,
  opportunityData,
  manualTimeTracking,
  isRepeated = false,
  isPrivateOpportunity,
  isVirtualOpportunity,
  isVisibleHashtagField,
  isOpportunityTimeDisable,
  isOpportunityDateDisable,
  setManualTimeTracking,
  isVisibleVolunteerLimit,
  setIsVirtualOpportunity,
  setIsPrivateOpportunity,
  setIsVisibleHashtagField,
  setIsVisibleVolunteerLimit,
}) => {
  const {startLoading} = useLoading();
  const isPast = useContext(PastOppoContext);
  const orgId = localStorage.getItem('organisationId');
  const {t} = useTranslation(['form', 'buttons', 'inputs']);

  const {errors, values, dirty, setFieldValue, setFieldTouched, handleSubmit}: FormikOpportunityMainForm =
    useFormikContext();

  const [isShowAdditional, setIsShowAdditional] = useState({isShow: false, isClose: false});
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const orgHashtags = useSelector(selectorGetHashtags);
  const availableInvite = useSelector(selectorGetPossibilityToInvite);
  const userRoles = useSelector(selectorGetUserRoles);

  const {startTime, endTime} = values;

  const getAllOrgHashtags = async (orgId: string) => {
    await startLoading(getAllHashtags(orgId));
  };

  const timePickerSwitcher = (fieldName: SwitcherFieldName) => {
    const startTimeMoment = moment(startTime, 'HH:mm', 'en', true);
    const endTimeMoment = moment(endTime, 'HH:mm', 'en', true);
    const isTimeValid = startTimeMoment.isValid() && endTimeMoment.isValid();
    const isTimeIncorrect =
      startTimeMoment.isSameOrAfter(endTimeMoment) &&
      moment(values.startDate, 'DD.MM.YYYY').isSame(values.endDate, 'day');

    const isStartTime = fieldName === 'startTime';
    const maxTimeString = '23:59';
    const minTimeString = '00:00';

    if (isTimeValid && isTimeIncorrect) {
      if (isStartTime) {
        const switchedEndTimeString = moment(startTime, 'HH:mm').add(1, 'h').format('HH:mm');
        const isSwitchedTimeIncorrect = startTimeMoment.isSameOrAfter(moment(switchedEndTimeString, 'HH:mm'));

        if (isSwitchedTimeIncorrect) {
          setFieldValue('endTime', maxTimeString);
        } else {
          setFieldValue('endTime', switchedEndTimeString);
        }
      } else {
        const switchedStartTimeString = moment(endTime, 'HH:mm').subtract(1, 'h').format('HH:mm');
        const isSwitchedTimeIncorrect = moment(switchedStartTimeString, 'HH:mm').isSameOrAfter(endTimeMoment);

        if (isSwitchedTimeIncorrect) {
          setFieldValue('startTime', minTimeString);
        } else {
          setFieldValue('startTime', switchedStartTimeString);
        }
      }
    }
  };

  const [isAllowPublish, setIsAllowPublich] = useState<boolean>(true);

  useEffect(() => {
    if (opportunityData) {
      if (userRoles?.length && opportunityData?.organisation?.id) {
        const rolesByOrganisation = userRoles.filter(
          (role) => Number(role.organizationId) === Number(opportunityData?.organisation?.id)
        );

        const isAllow = rolesByOrganisation.some(
          (role) => role.roleName === USER_ROLES.ADMIN || role.roleName || USER_ROLES.COORDINATOR
        );

        setIsAllowPublich(isAllow);
      }
    }
  }, [userRoles, opportunityData]);

  useEffect(() => {
    if (orgId) getAllOrgHashtags(orgId);
  }, [orgId]);

  useEffect(() => timePickerSwitcher('startTime'), [startTime]);
  useEffect(() => timePickerSwitcher('endTime'), [endTime]);

  const openConfirmUpdateClickHandler = () => setIsUpdateModalOpen(true);
  const closeConfirmUpdateClickHandler = () => setIsUpdateModalOpen(false);

  const submitClickHandler = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (isRepeated && isEdit && values.repeatableOpportunity) openConfirmUpdateClickHandler();
    else handleSubmit();
  };

  useEffect(() => {
    if ((values.volunteersMaxCount === 0 || values.volunteersMaxCount) && !isVisibleVolunteerLimit) {
      setFieldValue('volunteersMaxCount', null);
    }
  }, [values, isVisibleVolunteerLimit]);

  useEffect(() => {
    if (values?.userMinAge > values?.userMaxAge) {
      setFieldValue('userMaxAge', values?.userMinAge);
    }
    if (isEdit) setFieldTouched('user', true);
  }, [values]);

  useEffect(() => {
    if (!isVisibleHashtagField) setFieldValue('tagId', null);
  }, [values, isVisibleHashtagField]);

  const handleChangeVisibleLimit = (checked: boolean, _value?: string | undefined) => {
    setIsVisibleVolunteerLimit?.(checked);
  };

  const handleChangeVisibleHashtag = (checked: boolean, _value?: string | undefined) => {
    setIsVisibleHashtagField?.(checked);
  };

  // Firefox ignore type number
  const preventNonNumericalInput = (event: any) => {
    const charCode = typeof event.which === 'undefined' ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) event.preventDefault();
  };

  return (
    <Form className={cx(styles.form, isPast && styles.buttons)}>
      <Field
        name="imagePath"
        parentClassName={styles.upload_photo}
        big
        opportunityPhoto
        disabled={!isAllowPublish}
        component={UploadPhoto}
      />
      <Field
        name="opportunityName"
        label={t('form:opportunity.what')}
        placeholder={t('form:opportunity.name')}
        disabled={statusClose || !isAllowPublish}
        isLabelBold
        component={Input}
      />
      <Field
        name="opportunityDescription"
        label={`${t('form:opportunity.description')}:`}
        disabled={statusClose || !isAllowPublish}
        isLabelBold
        component={TextEditor}
      />
      <HashtagField
        options={orgHashtags}
        isVisibleHashtagField={isVisibleHashtagField}
        handleChangeVisibleHashtag={handleChangeVisibleHashtag}
        disabled={!isAllowPublish}
      />
      <Field
        name="additionalInformation"
        label={`${t('form:opportunity.additionalInfo')}:`}
        placeholder={t('inputs:placeholders.additionalInfo')}
        disabled={statusClose || !isAllowPublish}
        isLabelBold
        isPencilHidden
        component={TextArea}
      />
      <div className={styles.form__pickers}>
        <div className={styles.form__pickers_date}>
          <Field
            name="startDate"
            label={t('form:opportunity.when')}
            availableFuture
            disabled={statusClose || isOpportunityDateDisable || !isAllowPublish}
            isLabelBold
            disablePast
            component={DatePicker}
          />
        </div>
        <div className={styles.form__pickers_time}>
          <Field
            name="startTime"
            label={t('form:opportunity.startTime')}
            parentClassName={styles.timer}
            disabled={statusClose || isOpportunityTimeDisable || !isAllowPublish}
            isLabelBold
            disablePast={moment(values.startDate).isSame(moment(new Date()), 'day')}
            component={TimePicker}
          />
          <Field
            name="endTime"
            label={t('form:opportunity.endTime')}
            parentClassName={styles.timer}
            disabled={statusClose || isOpportunityTimeDisable || !isAllowPublish}
            isLabelBold
            disablePast={moment(values.startDate).isSame(moment(new Date()), 'day')}
            component={TimePicker}
          />
        </div>
      </div>
      <RepeatOpportunity isEdit={isEdit} disabled={!isAllowPublish} />
      <div className={styles.form__opportunitie_address}>
        <ButtonDefault
          disabled={statusClose || !isAllowPublish}
          classList={['secondary', 'lg', !manualTimeTracking ? 'secondary_active' : '']}
          title={t('form:opportunity.autoTimeTracking')}
          clickHandler={() => {
            setFieldValue('manualTimeTracking', false);
            setManualTimeTracking(false);
          }}
        />
        <ButtonDefault
          disabled={statusClose || !isAllowPublish}
          classList={['secondary', 'lg', manualTimeTracking ? 'secondary_active' : '']}
          title={t('form:opportunity.manualTimeTracking')}
          clickHandler={() => {
            setFieldValue('manualTimeTracking', true);
            setManualTimeTracking(true);
          }}
        />
      </div>
      <div className={styles.form__opportunitie_address}>
        <ButtonDefault
          disabled={statusClose || !isAllowPublish}
          classList={['secondary', !isVirtualOpportunity ? 'secondary_active' : '']}
          title={t('form:opportunity.local')}
          clickHandler={() => {
            setFieldValue('isVirtual', false);
            setIsVirtualOpportunity(false);
          }}
        />
        <ButtonDefault
          disabled={statusClose || !isAllowPublish}
          classList={['secondary', isVirtualOpportunity ? 'secondary_active' : '']}
          title={t('form:opportunity.virtual')}
          clickHandler={() => {
            setFieldValue('isVirtual', true);
            setIsVirtualOpportunity(true);
          }}
        />
      </div>
      <div className={styles.form__location}>
        {isVirtualOpportunity ? (
          <Field
            name="opportunityUrl"
            placeholder={t('form:opportunity.urlOrPhoneNumber')}
            disabled={!isAllowPublish}
            component={Input}
          />
        ) : (
          <Field
            name="address"
            placeholder={t('form:opportunity.location')}
            isAddressErrorText
            component={GoogleApiAutoCompleat}
          />
        )}
      </div>
      <div className={styles.form__limitCheckbox_wrapper}>
        <Checkbox
          disabled={statusClose || !isAllowPublish}
          changeHandle={handleChangeVisibleLimit}
          defaultChecked={isVisibleVolunteerLimit}
        />
        <p className={styles.form__limitCheckbox_label}>{t('form:opportunity.doesOppoHaveLimit')}</p>
      </div>
      {isVisibleVolunteerLimit && (
        <div className={styles.form__limit_wrapper}>
          <Field
            name="volunteersMaxCount"
            label={t('form:opportunity.howManyVolunteer')}
            placeholder=""
            disabled={statusClose || !isAllowPublish}
            parentClassName={styles.form__limit}
            min={1}
            onKeyPress={preventNonNumericalInput}
            type="number"
            isPencilHidden
            component={Input}
          />
        </div>
      )}
      {!isManual && (
        <>
          <Field name="isApprovalRequired">
            {({field}: FieldProps) => (
              <InputContainer isDisabledMargin>
                <div className={styles.form__join_type}>
                  <ButtonDefault
                    disabled={statusClose || !isAllowPublish}
                    classList={['secondary', 'lg', field.value === false ? 'secondary_active' : '']}
                    title={t('form:opportunity.anyoneCanJoin')}
                    clickHandler={() => setFieldValue(field.name, false)}
                  />
                  {availableInvite && (
                    <>
                      <ButtonDefault
                        disabled={statusClose || !isAllowPublish}
                        classList={['secondary', 'lg', field.value === true ? 'secondary_active' : '']}
                        title={t('form:opportunity.approvalRequired')}
                        clickHandler={() => setFieldValue(field.name, true)}
                      />
                      {errors.isApprovalRequired && <InputErrorHint errorText={errors.isApprovalRequired} />}
                    </>
                  )}
                </div>
              </InputContainer>
            )}
          </Field>
          {/* TODO: fix empty block height */}
          <div className={styles.coordinator}>
            <Field
              name="user"
              label={t('form:opportunity.chooseCoordinator')}
              isLabelBold
              disabled={!isAllowPublish}
              component={CoordinatorSelect}
            />
          </div>
          <Field name="opportunityTypes">
            {() => (
              <div className={styles.form__opportunitie_address}>
                {!schoolId && (
                  <ButtonDefault
                    disabled={statusClose || !isAllowPublish}
                    classList={['secondary', !isPrivateOpportunity ? 'secondary_active' : '']}
                    title={t('form:opportunity.public')}
                    clickHandler={() => {
                      setFieldValue('opportunityType', OPPORTUNITY_TYPES.PUBLIC);
                      setIsPrivateOpportunity(false);
                    }}
                  />
                )}
                {availableInvite && (
                  <ButtonDefault
                    disabled={statusClose || !isAllowPublish}
                    classList={['secondary', isPrivateOpportunity ? 'secondary_active' : '']}
                    title={t('form:opportunity.private')}
                    clickHandler={() => {
                      setFieldValue('opportunityType', OPPORTUNITY_TYPES.PRIVATE);
                      setIsPrivateOpportunity(true);
                    }}
                  />
                )}
              </div>
            )}
          </Field>
          {availableInvite && (
            <Field
              name="smartGroupId"
              label={t('form:opportunity.notifySmartGroup')}
              isLabelBold
              disabled={!isAllowPublish}
              component={SmartGroupSelect}
            />
          )}
          {!isEdit && (
            <Field
              name="notifyWhatsapp"
              label={t('form:opportunity.notifyInWhatsApp')}
              changeHandle={(value: boolean) => setFieldValue('notifyWhatsapp', value)}
              component={Checkbox}
              classNames={styles.notify__whatsapp}
            />
          )}
          <div className={styles.additional}>
            <span
              className={cx(styles.label, styles.additional__title)}
              onClick={() => changeVisibilityAdditionalOpportunityMainForm(isShowAdditional, setIsShowAdditional)}
            >
              {t('form:opportunity.additionalInfo')}
              <img
                className={cx(
                  styles.additional__title_arrow,
                  isShowAdditional.isShow && styles.additional__title_arrow_active
                )}
                src={ArrowSvg}
                alt=""
              />
            </span>
            {isShowAdditional.isShow && (
              <div
                className={cx(styles.additional__content, isShowAdditional.isClose && styles.additional__content_close)}
              >
                <div className={styles.form__forWho}>
                  <InputContainer isDisabledMargin isDisableHeight>
                    <WithWhoFilter
                      setFieldValue={setFieldValue}
                      fieldValues={values.withWho}
                      disabled={!isAllowPublish}
                    />
                  </InputContainer>
                </div>
                <span className={styles.label}>{t('form:opportunity.volunteerDetails')}</span>
                <div className={styles.details__content}>
                  <div className={styles.ageLimit}>
                    <Field
                      name="userMinAge"
                      placeholder={t('form:opportunity.minAge')}
                      classList={['md']}
                      options={ageSelectOptions}
                      isDisabledMargin
                      disabled={!isAllowPublish}
                      component={SelectDropdown}
                    />
                    <Field
                      name="userMaxAge"
                      placeholder={t('form:opportunity.maxAge')}
                      options={ageSelectOptions}
                      classList={['md']}
                      isDisabledMargin
                      disabled={!isAllowPublish}
                      component={SelectDropdown}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isAllowPublish && (
            <ButtonDefault
              submitType={false}
              classList={['primary', 'lg', 'publish']}
              title={t('buttons:button.publish')}
              disabled={!dirty}
              clickHandler={submitClickHandler}
            />
          )}
        </>
      )}

      {isEdit && isUpdateModalOpen && (
        <RepeatedActionTypeModal
          closePopupClickHandler={closeConfirmUpdateClickHandler}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          isModalOpen={isEdit && isUpdateModalOpen}
        />
      )}
    </Form>
  );
};

export default OpportunityMainForm;
