import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
//formik
import {Formik} from 'formik';
//redux
import {Store} from 'redux/root';
import {selectorGetPossibilityToInvite} from 'redux/organization-service/selector';
//urls
import {urls} from 'core/appUrls';
//validation
import {
  studentValidationSchema,
  volunteerSchemaAdding,
  volunteerSchemaStudentAdding,
  volunteerValidationSchema,
} from 'core/validation';
//constants
import {INITIAL_INVITE, INITIAL_INVITE_ADDING} from 'core/constants';
//functions
import {overTariffCount} from 'core/functions/overTariffCount';
import {invitePopupSubmitClickHandler} from 'core/functions/submitClickHandler';
//types
import {IInviteUserRequest} from '@joc/api-gateway';
//helpers
import {CurrentTab, getErrorMessage, getIsSetPreferredTab, getIsShowConfirmation} from './helpers';
//components
import Loader from 'shared/components/Loader';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import WhiteContainer from 'shared/components/WhiteContainer';
import PopupContainer from 'shared/components/PopupContainer';
import ResponseFailure from 'shared/components/ResponseFailure';
import InputImportList from 'shared/components/FormInputs/InputImportList';
import ButtonTabs from 'components/Organization/Volunteers/VolunteersPopups/InvitePopup/InviteTabs';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import InviteForm from './InviteForm';
import PopupCongrats from '../PopupCongrats';
import InvitedVolunteersTable from './InvitedVolunteersTable';
import InviteLink from './InviteLink';
import {AddByList} from './AddByList';
//styles
import styles from './InvitePopup.module.scss';

type InvitePopupParentProps = {
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
};

const InvitePopup: FC<InvitePopupParentProps> = ({setIsShowPopup}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessAdding, setIsSuccessAdding] = useState(false);
  const [customError, setCustomError] = useState<string>();
  const [initialFormikValues] = useState<IInviteUserRequest>(INITIAL_INVITE);
  const [initialFormikValuesAdding] = useState<any>(INITIAL_INVITE_ADDING);
  const [invitedVolunteers, setInvitedVolunteers] = useState<Array<IInviteUserRequest>>();
  const [isShowConfirmationClose, setIsShowConfirmationClose] = useState(false);
  const [isClosePopupConfirmation, setIsClosePopupConfirmation] = useState(false);
  const [isShowConfirmationChange, setIsShowConfirmationChange] = useState(false);
  const [isShowConfirmClear, setIsShowConfirmClear] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [currentTab, setCurrentTab] = useState<CurrentTab>(CurrentTab.form);
  const [preferredTab, setPreferredTab] = useState<CurrentTab>();

  const volunteersCountByTariff = useSelector((store: Store) => store.dashboardRedux.main.volunteersCountByTariff);
  const volunteersTotal = useSelector((store: Store) => store.dashboardRedux.main.volunteersTotal);
  const availableInvite = useSelector(selectorGetPossibilityToInvite);
  const orgId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.id);
  const schoolId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.schoolId);

  const {t} = useTranslation(['inviteColleagues', 'buttons']);

  const validationSchema = !!schoolId ? studentValidationSchema : volunteerValidationSchema;
  const validationAddingSchema = !!schoolId ? volunteerSchemaStudentAdding : volunteerSchemaAdding;

  useEffect(() => {
    if (currentTab !== CurrentTab.list || !invitedVolunteers?.length) return;

    const errorMessage = getErrorMessage(invitedVolunteers, validationSchema);
    if (errorMessage) setCustomError(errorMessage);
  }, [currentTab, invitedVolunteers, validationSchema]);

  const resetHandler = () => {
    setCustomError(undefined);
    setInvitedVolunteers([]);
  };

  const tabClickHandler = (tab: CurrentTab) => () => {
    if (currentTab !== tab) {
      const isSetPreferredTab = getIsSetPreferredTab(currentTab, isEmpty, invitedVolunteers?.length);
      if (isSetPreferredTab) {
        setPreferredTab(tab);
        setIsShowConfirmationChange(true);
      } else {
        setCurrentTab(tab);
      }
    }
  };

  const confirmationSubmitClickHandler = () => {
    if (preferredTab) {
      setCurrentTab(preferredTab);
      setPreferredTab(undefined);
    }
  };

  const closeButtonParentClickHandler = () => {
    const isShowConfirmation = getIsShowConfirmation(currentTab, isEmpty, invitedVolunteers?.length);
    if (isShowConfirmation || isSuccess || isSuccessAdding) {
      setTimeout(() => setIsShowPopup(false), 200);
    } else {
      setIsShowConfirmationClose(true);
    }
  };

  return (
    <PopupContainer
      isDisablePadding
      setIsShowPopup={setIsShowPopup}
      closeButtonParentClickHandler={closeButtonParentClickHandler}
      isClosePopupByParent={isClosePopupConfirmation}
    >
      {!!customError ? (
        <ResponseFailure message={customError} buttonClickHandler={resetHandler} isVertical />
      ) : isSuccess || isSuccessAdding ? (
        <PopupCongrats currentTab={currentTab} setIsShowPopup={setIsShowPopup} isSuccessAdding={isSuccessAdding} />
      ) : (
        <WhiteContainer title={!!schoolId ? t('inviteColleagues:addStudents') : t('inviteColleagues:addVolunteers')}>
          {availableInvite ? (
            <>
              <ButtonTabs currentTab={currentTab} tabClickHandler={tabClickHandler} />
              {currentTab === CurrentTab.form && (
                <Formik
                  initialTouched={false}
                  enableReinitialize
                  initialValues={initialFormikValues}
                  // TODO: [JF-1099] [fe] add check to schoolId
                  validationSchema={validationSchema}
                  onSubmit={(values: IInviteUserRequest) =>
                    invitePopupSubmitClickHandler(
                      [{...values, firstName: values.firstName.trim(), lastName: values.lastName.trim()}],
                      orgId,
                      setIsLoading,
                      setIsSuccess,
                      setCustomError
                    )
                  }
                >
                  <InviteForm
                    isLoading={isLoading}
                    setIsEmpty={setIsEmpty}
                    isShowConfirmationChange={isShowConfirmationChange}
                    setIsShowConfirmationChange={setIsShowConfirmationChange}
                    confirmationSubmitClickHandler={confirmationSubmitClickHandler}
                  />
                </Formik>
              )}
              {currentTab === CurrentTab.list && (
                <div className={styles.list}>
                  <div className={styles.list__wrapper}>
                    <div className={styles.list__nav}>
                      <InputImportList
                        setStateValue={setInvitedVolunteers}
                        listLength={invitedVolunteers?.length}
                        invitedUserType={!!schoolId ? 'student' : 'volunteer'}
                        exampleFilePath={
                          !!schoolId
                            ? `${process.env.PUBLIC_URL}/assets/documents/inviteStudentsTemplate.xlsx`
                            : `${process.env.PUBLIC_URL}/assets/documents/inviteVolunteersTemplate.xlsx`
                        }
                      />
                      {!!invitedVolunteers?.length && (
                        <ButtonDefault
                          classList={['secondary']}
                          title={t('buttons:button.clearAll')}
                          clickHandler={() => setIsShowConfirmClear(true)}
                        />
                      )}
                    </div>
                    {!!invitedVolunteers?.length &&
                      !!overTariffCount(volunteersTotal, volunteersCountByTariff, invitedVolunteers.length) && (
                        <>
                          <div className={styles.backRed}>
                            {t('inviteColleagues:messageEndTariff')}
                            <br />
                            {t('inviteColleagues:messagePlease')}
                            <Link to={urls.tariffPlans}>{t('inviteColleagues:tariffPlans')}</Link>
                          </div>
                        </>
                      )}
                    {!!invitedVolunteers?.length && (
                      <InvitedVolunteersTable
                        overTariffStartIndex={overTariffCount(
                          volunteersTotal,
                          volunteersCountByTariff,
                          invitedVolunteers.length
                        )}
                        volunteers={invitedVolunteers}
                      />
                    )}
                  </div>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    !!invitedVolunteers?.length && (
                      <ButtonDefault
                        disabled={
                          !!overTariffCount(volunteersTotal, volunteersCountByTariff, invitedVolunteers.length) ||
                          !!customError
                        }
                        classList={['primary', 'lg']}
                        title={t('buttons:button.next')}
                        clickHandler={() =>
                          invitePopupSubmitClickHandler(
                            invitedVolunteers,
                            orgId,
                            setIsLoading,
                            setIsSuccess,
                            setCustomError
                          )
                        }
                      />
                    )
                  )}
                  {isShowConfirmationChange && (
                    <PopupConfirmation
                      confirmClickHandler={() => {
                        confirmationSubmitClickHandler();
                        setIsShowConfirmationChange(false);
                        setInvitedVolunteers([]);
                      }}
                      cancelClickHandler={() => setIsShowConfirmationChange(false)}
                      setIsShowPopup={setIsShowConfirmationChange}
                    />
                  )}
                  {isShowConfirmClear && (
                    <PopupConfirmation
                      confirmClickHandler={() => {
                        setInvitedVolunteers([]);
                        setIsShowConfirmClear(false);
                      }}
                      cancelClickHandler={() => setIsShowConfirmClear(false)}
                      setIsShowPopup={setIsShowConfirmClear}
                    />
                  )}
                </div>
              )}
              {currentTab === CurrentTab.link && <InviteLink />}
              {currentTab === CurrentTab.adding && (
                <Formik
                  initialTouched={false}
                  enableReinitialize
                  initialValues={initialFormikValuesAdding}
                  validationSchema={validationAddingSchema}
                  onSubmit={(values: IInviteUserRequest) =>
                    invitePopupSubmitClickHandler(
                      [{...values, firstName: values.firstName.trim(), lastName: values.lastName.trim()}],
                      orgId,
                      setIsLoading,
                      setIsSuccessAdding,
                      setCustomError,
                      true
                    )
                  }
                >
                  <InviteForm
                    isShowPassword
                    isLoading={isLoading}
                    setIsEmpty={setIsEmpty}
                    isShowConfirmationChange={isShowConfirmationChange}
                    setIsShowConfirmationChange={setIsShowConfirmationChange}
                    confirmationSubmitClickHandler={confirmationSubmitClickHandler}
                    checkEmailExisting={!!schoolId}
                  />
                </Formik>
              )}
              {currentTab === CurrentTab.addByList && (
                <>
                  <AddByList
                    confirmationSubmitClickHandler={confirmationSubmitClickHandler}
                    setIsEmpty={setIsEmpty}
                    setIsSuccess={setIsSuccess}
                    setCustomError={setCustomError}
                    isShowConfirmationChange={isShowConfirmationChange}
                    setIsShowConfirmationChange={setIsShowConfirmationChange}
                    customError={customError}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {t('inviteColleagues:messageReached')} <Link to={urls.tariffPlans}>{t('inviteColleagues:here')}</Link>{' '}
              {t('inviteColleagues:messageReturn')}
            </>
          )}
          {isShowConfirmationClose && (
            <PopupConfirmation
              confirmClickHandler={() => {
                setIsClosePopupConfirmation(true);
                setTimeout(() => setIsShowPopup(false), 200);
              }}
              cancelClickHandler={() => setIsShowConfirmationClose(false)}
              setIsShowPopup={setIsShowConfirmationClose}
              isDisableContentMarginTop
            />
          )}
        </WhiteContainer>
      )}
    </PopupContainer>
  );
};

export default InvitePopup;
